import React, {useState} from 'react';
import {Formik} from 'formik';
import * as bp4 from '@blueprintjs/core';
import * as bp4s from '@blueprintjs/select';
import {api} from "../api";

export default function ({activity, yearWeek, isOpen, onClose}
                             : {activity: any, yearWeek: {year: number, week: number}, isOpen: boolean, onClose: () => void}) {
    if(activity === null || activity === undefined)
        return <></>;
    const [error, setError] = useState<string|null>(null);
    return <bp4.Dialog
        isOpen={isOpen}
        onClose={onClose}
        isCloseButtonShown={true}
        title={`${activity.name} (${yearWeek.year} / ${yearWeek.week})`}>
        <Formik initialValues={{
            morning: activity.morning,
            afternoon: activity.afternoon,
            capacity: activity.capacity,
            units: activity.units
        }} onSubmit={async (values, props) => {
            try {
                const response = await fetch(api(`/api/v1/activities/${activity.id}/year/${yearWeek.year}/week/${yearWeek.week}`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(values)
                }));
                if (response.status !== 200) {
                    setError(`Error (Status Code: ${response.status}): ${await response.text()}`);
                } else {
                    props.resetForm();
                    onClose();
                }
            } catch (e) {
                setError(`Unknown Error`);
                console.log(e);
            }
        }}>
            {({
                  handleSubmit,
                values,
                setFieldValue,
                  resetForm
            }) => <>
                <form onSubmit={handleSubmit}>
                    <div className={bp4.Classes.DIALOG_HEADER}>
                        {error !== null && <bp4.NonIdealState title="Fehler!" icon="error">
                            <p>{error}</p>
                        </bp4.NonIdealState>}
                    </div>
                    <div className={bp4.Classes.DIALOG_BODY}>
                        <bp4.FormGroup label="Tageszeiten" labelInfo="Zu welchen Tageszeiten ist die Aktivität erlaubt.">
                            <bp4.Checkbox checked={values.morning} onChange={({currentTarget: {checked}}) => setFieldValue('morning', checked)} label="Vormittags" />
                            <bp4.Checkbox checked={values.afternoon} onChange={({currentTarget: {checked}}) => setFieldValue('afternoon', checked)} label="Nachmittags" />
                        </bp4.FormGroup>
                        <bp4.FormGroup label="Kapazität" labelInfo="Wieviele Einheiten dürfen gleichzeitig sein.">
                            <bp4.NumericInput value={values.capacity} onValueChange={v => setFieldValue('capacity', v)} min={0} max={100} />
                        </bp4.FormGroup>
                        <bp4.FormGroup label="Einheiten" labelInfo="Wieviele Einheiten dürfen täglich stattfinden.">
                            <bp4.NumericInput value={values.units} onValueChange={v => setFieldValue('units', v)} min={0} max={100} />
                        </bp4.FormGroup>
                    </div>
                    <div className={bp4.Classes.DIALOG_FOOTER}>
                        <div className={bp4.Classes.DIALOG_FOOTER_ACTIONS}>
                            <bp4.Button intent={bp4.Intent.SUCCESS} text="Speichern" icon="saved" type="submit" />
                            <bp4.Button intent={bp4.Intent.DANGER} text="Abbrechen" icon="trash" onClick={() => {
                                resetForm();
                                onClose();
                            }} />
                        </div>
                    </div>
                </form>
            </>}

        </Formik>

    </bp4.Dialog>;
}