import React, {useEffect, useState} from 'react';
import * as bp4 from '@blueprintjs/core';
import * as bp4s from '@blueprintjs/select';
import {api} from "../api";
import {Formik} from 'formik';

function EditActivityDialog({isOpen, close, activity} : {isOpen: boolean, close: () => void, activity: any}) {
    return <bp4.Dialog isOpen={isOpen} onClose={close} title={activity.isNew? 'Neu' : `${activity.name} (${activity.level})`}>
        <div className={bp4.Classes.DIALOG_BODY}>
            <Formik initialValues={activity} onSubmit={async (values) => {
                console.log('Hey!');
                if(values.isNew) {
                    const response = await fetch(api('/api/v1/activities'), {
                        body: JSON.stringify({...values, isNew: undefined}),
                        headers: {
                            "Content-Type": "application/json"
                        },
                        method: 'POST'
                    });
                    if(response.status === 200)
                        close();
                } else {
                    const response = await fetch(api(`/api/v1/activities/${values.id}`), {
                        body: JSON.stringify({...values, isNew: undefined}),
                        headers: {
                            "Content-Type": "application/json"
                        },
                        method: 'PATCH'
                    });
                    if(response.status === 200)
                        close();
            }}}>
                {({
                    values,
                    handleSubmit,
                    setFieldValue
                  }) => <form onSubmit={handleSubmit}>
                    <bp4.FormGroup label="Name" labelInfo="(*)">
                        <bp4.InputGroup name="name" 
                                        required
                                        placeholder="Tennis, Segeln, ..." 
                                        value={values.name} 
                                        onChange={({currentTarget: {value}}) => setFieldValue('name', value)} />
                    </bp4.FormGroup>
                    <bp4.FormGroup label="Grad">
                        <bp4s.Select2 items={['BEGINNERS','INTERMEDIATE','NONE']} itemRenderer={(item, props) => {
                            return <bp4.MenuItem itemID={item}
                                                 key={item}
                                                 onClick={props.handleClick}
                                                 {...props}
                                                 text={item} />
                        }} activeItem={values.level} onItemSelect={(item) => setFieldValue('level', item)}
                        popoverProps={{usePortal: false}} fill>
                            <bp4.Button text={values.level} icon="double-caret-vertical" />
                        </bp4s.Select2>
                    </bp4.FormGroup>
                    <bp4.FormGroup label="Einheiten"
                                   labelInfo={`(maximal ${values.units * values.capacity * values.participantLimit.to} Teilnehmer)`}
                                   helperText="Anzahl der maximalen Einheiten die an einem Tag stattfinden können.">
                        <bp4.Slider value={values.units} min={0} max={8} onChange={(value) => setFieldValue('units', value)} />
                    </bp4.FormGroup>
                    <bp4.FormGroup label="Kapazität">
                        <bp4.Slider value={values.capacity} min={0} max={10} onChange={(value) => setFieldValue('capacity', value)} />
                    </bp4.FormGroup>
                    <bp4.FormGroup label="Zeiten">
                        <bp4.Checkbox checked={values.morning} onChange={({currentTarget: {checked}}) => setFieldValue('morning', checked)}>
                            Morgen
                        </bp4.Checkbox>
                        <bp4.Checkbox checked={values.afternoon} onChange={({currentTarget: {checked}}) => setFieldValue('afternoon', checked)}>
                            Nachmittag
                        </bp4.Checkbox>
                    </bp4.FormGroup>
                    <bp4.FormGroup label="Aktiv">
                        <bp4.Checkbox checked={!values.disabled} onChange={({currentTarget: {checked}}) => setFieldValue('disabled', !checked)} />
                    </bp4.FormGroup>
                    <bp4.FormGroup label="Teilnehmer">
                        <bp4.RangeSlider min={0} max={60}
                                         labelStepSize={10}
                                         onChange={([from, to]) => {
                                             setFieldValue('participantLimit', {from, to});
                                         }} value={[values.participantLimit.from, values.participantLimit.to]} />
                    </bp4.FormGroup>
                    <bp4.ButtonGroup>
                        <bp4.Button intent={bp4.Intent.PRIMARY} icon="saved" type="submit" text="Speichern" />
                    </bp4.ButtonGroup>
                </form> }
            </Formik>
        </div>
    </bp4.Dialog>;
}

function ActivitySettings() {
    const [activities, setActivities] = useState<any[]>([]);
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [selectedActivity, setSelectedActivity] = useState<any>({
        isNew: true,
        name: '',
        level: 'NONE',
        capacity: 0,
        morning: false,
        afternoon: false,
        participantLimit: {
            from: 0,
            to: 1
        }
    });
    useEffect(() => {
        (async ()  => {
            const response = await fetch(api('/api/v1/activities'));
            if(response.status === 200)
                setActivities(await response.json());
        })();
    }, [setActivities]);
    return <div>
        <EditActivityDialog isOpen={showDialog} close={async () => {
            setShowDialog(false);
            const response = await fetch(api('/api/v1/activities'));
            if(response.status === 200)
                setActivities(await response.json());
        }} activity={selectedActivity} />
        <bp4.ButtonGroup>
            <bp4.Button small icon="add" text="Neu" intent={bp4.Intent.SUCCESS} onClick={() => {
                setShowDialog(true);
                setSelectedActivity({
                    isNew: true,
                    name: '',
                    level: 'NONE',
                    capacity: 0,
                    morning: false,
                    afternoon: false,
                    participantLimit: {
                        from: 0,
                        to: 1
                    }
                });
            }} />
        </bp4.ButtonGroup>
        <bp4.HTMLTable bordered striped condensed>
            <thead>
            <tr>
                <th>Name</th>
                <th>Stufe</th>
                <th>Standard-Kapazität</th>
                <th>Vormittags</th>
                <th>Nachmittags</th>
                <th>Aktiv</th>
                <th>-</th>
            </tr>
            </thead>
            <tbody>
            {activities.map(activity => <tr>
                <td>{activity.name}</td>
                <td>{activity.level}</td>
                <td>{activity.capacity}</td>
                <td><bp4.Checkbox checked={activity.morning} disabled={true} /></td>
                <td><bp4.Checkbox checked={activity.afternoon} disabled={true} /></td>
                <td><bp4.Checkbox checked={!activity.disabled} disabled={true} /></td>
                <td>
                    <bp4.ButtonGroup>
                        <bp4.AnchorButton small icon="edit" intent={bp4.Intent.NONE} onClick={() => {
                            setSelectedActivity(activity);
                            setShowDialog(true);
                        }} />
                        <bp4.AnchorButton small icon="delete" intent={bp4.Intent.DANGER} onClick={async () => {
                            await fetch(api(`/api/v1/activities/${activity.id}`), {method: 'DELETE'});
                            const response = await fetch(api('/api/v1/activities'));
                            if(response.status === 200)
                                setActivities(await response.json());
                        }} />
                    </bp4.ButtonGroup>
                </td>
            </tr>)}
            </tbody>
        </bp4.HTMLTable>
    </div>;
}

export function SettingsDialog({isOpen = false, close = () => {}} : {isOpen: boolean, close: () => void}) {
    return <bp4.Dialog isOpen={isOpen} onClose={close} title="Einstellungen" style={{width: 'fit-content'}}>
        <div className={bp4.Classes.DIALOG_BODY}>
            <bp4.Tabs selectedTabId="activities">
                <bp4.Tab id="activities" title="Aktivitäten" panel={<ActivitySettings />} />
            </bp4.Tabs>
        </div>
    </bp4.Dialog>;
}