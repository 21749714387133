import React from 'react';
import { Link } from 'react-router-dom';
import * as bp3 from '@blueprintjs/core';
import classNames from 'classnames';

import * as it from '../iter';
import * as utils from '../utils';
import * as state from '../state';

import { Ellipsis, level } from './common';
import { SlotPopoverContent } from './SlotPopoverContent';
import {api, DELETE, POST} from "../api";
import {IconNames} from "@blueprintjs/icons";

export const easyTime = (time: string) => {
  const [hh, mm] = time.split(':');
  return [Number(hh), mm].join(':');
};
export const englishDay2GermanDay = (x: string) => {
  switch (x) {
    case "MONDAY": return "Mo.";
    case "TUESDAY": return "Di.";
    case "WEDNESDAY": return "Mi.";
    case "THURSDAY": return "Do.";
    case "FRIDAY": return "Fr.";
    case "SATURDAY": return "Sa.";
    case "SUNDAY": return "So.";
    default: throw Error();
  }
};

const bookingTimeToStr = ({ morning, afternoon } : {morning: boolean, afternoon: boolean}, { morningStartDay, morningEndDay, afternoonStartDay, afternoonEndDay }: { morningStartDay: string, morningEndDay: string, afternoonStartDay: string, afternoonEndDay: string }) => {
  if(morning) {
    return `${englishDay2GermanDay(morningStartDay)} - ${englishDay2GermanDay(morningEndDay)}`;
  }
  return `${englishDay2GermanDay(afternoonStartDay)} - ${englishDay2GermanDay(afternoonEndDay)}`;
}

const School = React.memo(function School({ id, name, participants, bookingTime, defaultExpanded, slot }: { id: string, name: string, participants: any[], bookingTime: any, defaultExpanded?: boolean, slot: any }) {
  const [isOpen, setIsOpen] = React.useState(defaultExpanded ?? false);
  React.useEffect(() => { if (typeof defaultExpanded !== 'undefined') setIsOpen(defaultExpanded) }, [defaultExpanded]);
  // console.log(bookingTime)

  return (
    <div
      className={classNames({ 'App-school': true, 'App-school-opened': isOpen })}
      onClick={React.useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation()
        setIsOpen(!isOpen);
      }, [isOpen])}
    >
      <div className="space-between">
        <Ellipsis className="App-school-title"
                  draggable
                  onDragStart={(event: any) => {
                    event.dataTransfer.setData("participants", JSON.stringify(participants));
                    event.dataTransfer.setData("slotId", slot.id);
                    event.dataTransfer.setData("slotName", slot.activity.name);
                    event.dataTransfer.setData("schoolId", id);
                  }}>
          <bp3.Icon icon={IconNames.DRAG_HANDLE_HORIZONTAL}/>
          {' '}
          {name}
        </Ellipsis>
        <span className={bp3.Classes.TEXT_MUTED}>{bookingTimeToStr(slot, bookingTime)}</span>
      </div>
      <bp3.Collapse isOpen={!isOpen}>
        <div className={classNames(['App-school-sub', bp3.Classes.TEXT_MUTED])}>
          <div>{participants.length} Schüler</div>
        </div>
      </bp3.Collapse>
      <bp3.Collapse isOpen={isOpen}>
        <div className={classNames(['App-school-exp', bp3.Classes.TEXT_MUTED])}>
          {participants.map(p => (
            <Ellipsis key={p.id}
                      style={{ display: 'block' }}
                      draggable
                      onDragStart={(event: any) => {
                        event.dataTransfer.setData("participants", JSON.stringify([{
                          ...p
                        }]));
                        event.dataTransfer.setData("schoolId", id);
                        event.dataTransfer.setData("slotId", slot.id);
                        event.dataTransfer.setData("slotName", slot.activity.name);
                      }}>
              <bp3.Icon icon={IconNames.DRAG_HANDLE_HORIZONTAL}/>
              {' '}
              {p.name}
            </Ellipsis>
          ))}
        </div>
      </bp3.Collapse>
    </div>
  );
});

export const Slot = React.memo(function Slot({ slot, isOpen, href, activeSchool, expandAll }: { slot: any, isOpen: boolean, href: string, activeSchool?: any, expandAll?: boolean }) {
  const participantsBySchool = React.useMemo(() => it.groupBy(slot.participants as any[], p => p.school.id), [slot]);

  const warning = utils.useRecoilValueLoadable(state.warningBySlot(slot.id)).contents;
  const withTitle = !!activeSchool;

  const participants = React.useMemo(() => {
    const pairs = [...participantsBySchool];
    const activeX = pairs.find(([id]) => id === activeSchool?.id);
    const xs = pairs.filter(([id]) => id !== activeSchool?.id);
    return activeX != null ? [activeX, ...xs] : xs;
  }, [activeSchool, participantsBySchool])

  // console.log(slot)

  const levelLabel = level(slot.activity.level);
  // const freeSeats = slot.participantLimit.to - slot.participants.length;

  const moveParticipant = async (slot: any, participant: any, oldSlot: any, reload: any) => {
    console.log(`Moving ${participant.name} from ${oldSlot.name} to ${slot.activity.name}!`);
    console.log(`participantId=${participant.id}, oldSlotId=${oldSlot.id}, slotId=${slot.id}`);

    const requests: Request[] = [];
    requests.push(api(`/api/v1/slots/${oldSlot.id}/students`, { method: DELETE, body: [participant.id] }));
    requests.push(api(`/api/v1/slots/${slot.id}/students`, { method: POST, body: [participant] }));
    try {
      let allOk = true;
      for(let i = 0; i < requests.length; i++) {
        const req = requests[i];
        const response = await fetch(req);
        if(!response.ok) {
          allOk = false;
          break;
        }
      }
      if(allOk) {
        if(reload) {
          window.location.reload();
        }
      } else {
        alert(`Es ist ein Fehler beim verschieben des Schülers ${participant.name} von ${oldSlot.name} nach ${slot.name} aufgetreten!`);
      }
    } catch {
      alert(`Es ist ein Fehler beim verschieben des Schülers ${participant.name} von ${oldSlot.name} nach ${slot.name} aufgetreten!`);
    }
  };

  const moveParticipants = React.useCallback(async (oldSlot: any, drop: any) => {
    const newParticipants = JSON.parse(drop.dataTransfer.getData("participants"));
    const schoolId = drop.dataTransfer.getData("schoolId");
    for(const newParticipant of newParticipants) {
      await moveParticipant(slot, {
        id: newParticipant.id,
        name: newParticipant.name,
        schoolId: schoolId
      }, oldSlot, false);
    }
    window.location.reload();
  }, [slot])

  return (
    <bp3.Popover
      content={<SlotPopoverContent slot={slot} warning={warning} />}
      isOpen={isOpen}
      captureDismiss={true}
      position={bp3.Position.RIGHT}
      targetProps={{ style: { height: '100%' } }}
      canEscapeKeyClose={false}
      inheritDarkTheme={true}
      enforceFocus={false}
    >
      <Link to={href} style={{ textDecoration: 'none', color: 'unset' }}><bp3.Card
        className={classNames({ 'App-slot': true, 'bp3-intent-danger': !!warning })}
        elevation={isOpen ? bp3.Elevation.TWO : bp3.Elevation.ZERO}
        style={{ transform: `translateY(${isOpen ? '-3px' : '0px'})` }}
      >
        <header className={bp3.Classes.DIALOG_HEADER}>
          {withTitle ? <Ellipsis style={{ marginBottom: 5, fontWeight: 600 }}>{slot.activity.name}{levelLabel && ' — '}{levelLabel}</Ellipsis> : null}
          <div className="space-between">
            <span style={{ fontWeight: withTitle ? 'normal' : 'bold', whiteSpace: 'nowrap', display: 'inline-block', marginRight: 5 }}>{easyTime(slot.start)}{' – '}{easyTime(slot.end)}</span>
            {/*Replaced this due to wierd bug. Slots are in general always booked between MON-FRI!*/}
            {/*<span className={bp3.Classes.TEXT_MUTED}>{days2str(slot.days)}</span>*/}
            <span className={bp3.Classes.TEXT_MUTED} >{englishDay2GermanDay(slot.startDay)} - {englishDay2GermanDay(slot.endDay)}</span>
          </div>
        </header>
        <section className={bp3.Classes.DIALOG_BODY}
                 onDragOver={e => e.preventDefault()}
                 onDrop={drop => {
                   const oldSlot = {
                     id: drop.dataTransfer.getData("slotId"),
                     name: drop.dataTransfer.getData("slotName")
                   };
                   console.log('Moving!');
                   moveParticipants(oldSlot, drop)
                       .catch(err => alert(`Schüler konnte nicht verschoben werden! ${err}`))
                 }}>
          {slot.description?.infoText && <p className={bp3.Classes.TEXT_SMALL} style={{overflowWrap: 'break-word'}}>{slot.description.infoText}</p>}
          {participants.map(([id, participants]) => (
            <School
              key={id}
              id={id}
              name={participants[0].school.name}
              participants={participants}
              bookingTime={participants[0].school.bookingTime}
              defaultExpanded={id === activeSchool?.id || expandAll}
              slot={slot}
            />
          ))}
        </section>
        <footer className={classNames([bp3.Classes.DIALOG_FOOTER, bp3.Classes.TEXT_MUTED])}>
          {/* <Ellipsis>{freeSeats}{freeSeats === 1 ? ' freier Platz' : ' freie Plätze'}</Ellipsis> */}
          <Ellipsis>{slot.participants.length} / {slot.participantLimit.to}</Ellipsis>
          {slot.locationName && <Ellipsis className={bp3.Classes.TEXT_MUTED}>{slot.locationName ?? ''}</Ellipsis>}
          <Ellipsis>{slot.teacherName ?? ''}</Ellipsis>
        </footer>
        {slot.description?.slugLine && <p className={bp3.Classes.TEXT_SMALL} style={{overflowWrap: 'break-word'}}>{slot.description.slugLine}</p>}
        {/* {slot.location && <footer className={classNames([bp3.Classes.DIALOG_FOOTER, bp3.Classes.TEXT_MUTED])}>
          <div>
            <Ellipsis className={bp3.Classes.TEXT_MUTED}>{slot.location.name}</Ellipsis>
          </div>
        </footer>} */}
      </bp3.Card></Link>
    </bp3.Popover>
  )
});

