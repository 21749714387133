import React from "react";
import * as bp3 from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { MultiSelect, ItemRenderer, ItemPredicate } from '@blueprintjs/select';

export function StringMultiSelect({ items, iconSelected, iconBlank, selection = [], onSelectionChange, disabled, ...props }: { [x: string]: any, selection: string[], items: string[], onSelectionChange: (k: string[]) => void }) {
  const itemRenderer: ItemRenderer<string> = React.useCallback((key: string, { modifiers, handleClick }) => {
    if (!modifiers.matchesPredicate) { return null; }
    return (
      <bp3.MenuItem
        active={modifiers.active}
        icon={selection.includes(key) ? (iconSelected ?? IconNames.TICK) : (iconBlank ?? IconNames.BLANK)}
        key={key}
        onClick={handleClick}
        text={key}
        shouldDismissPopover={false}
      />
    );
  }, [iconSelected, iconBlank, selection]);

  // const renderMenu: ItemListRenderer<string> = React.useCallback(({ filteredItems: items, itemsParentRef, query, renderItem }: IItemListRendererProps<string>) => {
  //   return (
  //     <bp3.Menu ulRef={itemsParentRef}>
  //       {items.length
  //         ? <FixedSizeList
  //           height={280}
  //           itemCount={items.length}
  //           itemSize={30}
  //           width={235}
  //         >
  //           {({ index, style }) => {
  //             const item = renderItem(items[index], index);
  //             if (item == null) return null;
  //             return <item.type {...item.props} style={style} />;
  //           }}
  //         </FixedSizeList>
  //         : noEntry ?? <bp3.MenuItem disabled={true} text="No results." />
  //       }
  //     </bp3.Menu>
  //   );
  // }, [noEntry]);

  const filterSkills: ItemPredicate<string> = React.useCallback((query, key) => {
    const normalizedKey = key.toLowerCase();
    const normalizedQuery = query.toLowerCase();
    return normalizedKey.includes(normalizedQuery);
  }, [])

  const handleToggle = React.useCallback((k) => {
    onSelectionChange(selection.includes(k)
      ? selection.filter(x => x !== k)
      : selection.concat([k]))
  }, [onSelectionChange, selection]);

  return (
    <MultiSelect<string>
      noResults={React.useMemo(() => <bp3.MenuItem disabled={true} text="No results." />, [])}
      tagRenderer={(x => x)}
      tagInputProps={{
        onRemove: x => handleToggle(x),
        tagProps: { minimal: true },
        disabled,
      }}
      popoverProps={{ minimal: true }}
      {...props}
      items={items}
      itemPredicate={filterSkills}
      itemsEqual={React.useCallback((a: string, b: string) => a.toLowerCase() === b.toLowerCase(), [])}
      selectedItems={selection}
      itemRenderer={itemRenderer}
      onItemSelect={handleToggle}
    // itemListRenderer={renderMenu}
    />
  );
}
