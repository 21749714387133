import React, {useEffect, useState} from 'react';
import * as styles from './AddOrEditStudentDialog.module.css';
import * as bp4 from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import {Formik} from 'formik';
import {api} from "../api";
import ErrorState from "../components/ErrorState";
import {translateLevel} from "../utils";

export default function AddOrEditStudentDialog({
    isOpen = false,
    close = () => {},
    student = {isNew: true},
    yearWeek = {year: '0', weekNumber: '1'},
    bookingId = ''
                                               } : {
    isOpen: boolean,
    close: () => void,
    student: any,
    yearWeek: {year: string | number, weekNumber: string | number},
    bookingId: string
}) {
    const [loadingState, setLoadingState] = useState<'loading' | 'empty' | 'error' | 'loaded'>('loading');
    const [activities, setActivities] = useState<any[]>([]);
    const [error, setError] = useState<string | null>('');
    useEffect(() => {
        (async () => {
            try {
                const response = await fetch(api(`/api/v1/plans/${yearWeek.year}/${yearWeek.weekNumber}/activities`));
                if(response.status !== 200) {
                    setError(`HTTP Error ${response.status}: ${await response.text()}`);
                    setLoadingState('error');
                } else {
                    setActivities(await response.json());
                    setLoadingState('loaded');
                }
            } catch (e) {
                setError(`Unknown Error: ${e}`);
                setLoadingState('error');
            }
        })();
    }, [setLoadingState, setActivities, setError])

    return <bp4.Dialog
        icon={student.isNew? IconNames.ADD : IconNames.EDIT}
        title={student.isNew? 'Neuer Schüler' : `Bearbeiten: ${student.name}`}
        isOpen={isOpen}
        onClose={close}>
        <Formik
            initialValues={student.isNew? {
                name: '',
                activityIds: []
            } : {
                id: student.id,
                name: student.name,
                activityIds: student.activities.map((activity: any) => activity.id)
            }}
            onSubmit={async (values, {resetForm}) => {
                try {
                    if (student.isNew) {
                        const response = await fetch(api(`/api/v1/bookings/${bookingId}/students/new`, {
                            method: 'POST',
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                                name: values.name,
                                activityIds: values.activityIds,
                                gender: 'UNKNOWN',
                                schoolId: bookingId,
                                forceCreate: false
                            })
                        }));
                        if(response.status === 400) {
                            const {message} = await response.json();
                            window.alert(message);
                        } else if(response.status !== 200) {
                            setError(`HTTP Error ${response.status}: ${await response.text()}`);
                            setLoadingState('error');
                        } else {
                            resetForm();
                            close();
                        }
                    } else {
                        const response = await fetch(api(`/api/v1/students/${values.id}`, {
                            method: 'PATCH',
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                                name: values.name,
                                activityIds: values.activityIds,
                                forceUpdate: false
                            })
                        }));
                        if(response.status === 400) {
                            const {message} = await response.json();
                            window.alert(message);
                        } else if(response.status !== 200) {
                            setError(`HTTP Error ${response.status}: ${await response.text()}`);
                            setLoadingState('error');
                        } else {
                            resetForm();
                            close();
                        }
                    }
                } catch (e) {
                    setError(`Unknown Error: ${e}`);
                    setLoadingState('error');
                }
            }}>
            {({
                  handleSubmit,
                  values,
                  setFieldValue
              }) => <>
                <ErrorState show={loadingState === 'error'} error={error} />
                <form onSubmit={handleSubmit}>
                    <div className={bp4.Classes.DIALOG_BODY}>

                        <bp4.FormGroup label="Name" labelInfo="(*)">
                            <bp4.InputGroup
                                required
                                fill
                                minLength={2}
                                maxLength={256}
                                value={values.name}
                                onChange={({currentTarget: {value}}) => setFieldValue('name', value)} />
                        </bp4.FormGroup>

                        <bp4.FormGroup label="Aktivitäten" labelInfo="Jede Person kann bis zu zwei Aktivitäten angeben.">

                            {loadingState === 'loading'
                                && <bp4.NonIdealState
                                    title="Laden ..."
                                    icon={IconNames.DATA_CONNECTION}
                                    description={<>
                                        <bp4.Spinner />
                                    </>} />}

                            <div style={{display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)'}}>
                                {activities.filter(({disabled} : {disabled: boolean}) => !disabled).map(activity =>
                                    <bp4.Checkbox
                                        key={activity.id}
                                        checked={values.activityIds.includes(activity.id)}
                                        disabled={!values.activityIds.includes(activity.id) && values.activityIds.length >= 2}
                                        onChange={({currentTarget: {checked}}) => {
                                            if(checked) {
                                                if(!values.activityIds.includes(activity.id))
                                                    setFieldValue('activityIds', [...values.activityIds, activity.id]);
                                            } else {
                                                setFieldValue('activityIds', values.activityIds.filter((id: any) => id !== activity.id));
                                            }
                                    }}>
                                        {activity.name} {activity.level !== 'NONE' && translateLevel(activity.level)}
                                    </bp4.Checkbox>)}
                            </div>


                        </bp4.FormGroup>

                        <div className={bp4.Classes.DIALOG_FOOTER}>
                            <div className={bp4.Classes.DIALOG_FOOTER_ACTIONS}>
                                <bp4.Button
                                    type="submit"
                                    intent={bp4.Intent.SUCCESS}
                                    icon={IconNames.SAVED}
                                    text="Speichern" />
                                <bp4.Button
                                    intent={bp4.Intent.DANGER}
                                    icon={IconNames.TRASH}
                                    text="Abbrechen"
                                    onClick={() => close()} />
                            </div>
                        </div>
                    </div>

                </form>

            </>}
        </Formik>
    </bp4.Dialog>;
}