import React from 'react';
import { useNavigate, useMatch } from 'react-router-dom';
import * as bp3 from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import * as util from '../utils';
import * as state from '../state';
import { ContextMenu } from './SlotPopoverContent';
import {api, DELETE} from "../api";
import { EditItineraryDialog } from "./EditItineraryDialog";

const itiFormatter = new Intl.DateTimeFormat('de-AT', {
  hour: 'numeric',
  minute: 'numeric',
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
});

const dayTimes: any = {
  'All': 'Ganztägig',
  'Morning': 'Vormittags',
  'Afternoon': 'Nachmittags'
}

export function Itinerary({ activeSchool, permissions = 'all' }: { activeSchool: any, permissions: 'all' | 'restricted' }) {
  const {id, schoolInfo, bookingTime, lunchBreak, teachersCount, sportUnits, allowedDayTimes} = activeSchool;
  const routingHistory = useNavigate();
  const { yyyy, ww } = useMatch({path: '/:yyyy/:ww/:sort/:filter/:routeSchoolId'})?.params as unknown as { yyyy: string, ww: string, sort: string, filter: string, routeSchoolId: string } ?? {};
  const [year, week] = [yyyy, ww].map(Number);
  const [isOpen, setIsOpen] = React.useState(false);
  const deleteBooking = React.useCallback(async () => {
    if(!window.confirm(`Wollen Sie die Buchung von ${schoolInfo.name} wieder öffnen?`)) {
      return;
    }
    try {
      const res = await fetch(api(`/api/v1/bookings`, { method: DELETE, body: [id] }));
      if (res.ok) {
        routingHistory(`/${year}/${week}/Sportart/Alle`);
        //routingHistory.go(0);
      }
    } catch { console.log('Error!'); }
  }, [id, routingHistory, year, week]);

  if (!bookingTime) return null;

  const { contents: warnings } = permissions === 'all'?  util.useRecoilValueLoadable(state.warningsBySchool(id)) : {contents: []};

  return (
    <section className="App-itinerary">
      <EditItineraryDialog isOpen={isOpen}
                           setIsOpen={setIsOpen}
                           school={activeSchool}
                           permissions={permissions} />
      <h1>
        {permissions === 'all' && <bp3.Button
            intent={bp3.Intent.DANGER}
            icon={IconNames.UNDO}
            onClick={() => deleteBooking()}
            minimal />}
        {' '}
        <bp3.Button
            intent={bp3.Intent.NONE}
            icon={IconNames.EDIT}
            onClick={() => setIsOpen(true)}
            minimal />
        {' '}
        {schoolInfo?.schoolName}
      </h1>
      <div className="space-between">
        <dl className="App-timeline">
          <dt className={bp3.Classes.TEXT_MUTED}>Ankunft</dt>
          <dd>{itiFormatter.format(new Date(bookingTime.from))}</dd>
          <dt className={bp3.Classes.TEXT_MUTED}>Sport Start</dt>
          <dd>{itiFormatter.format(new Date(bookingTime.sportStart))}</dd>
          <dt className={bp3.Classes.TEXT_MUTED}>Sport Ende</dt>
          <dd>{itiFormatter.format(new Date(bookingTime.sportEnd))}</dd>
          <dt className={bp3.Classes.TEXT_MUTED}>Abfahrt</dt>
          <dd>{itiFormatter.format(new Date(bookingTime.to))}</dd>
          <dt className={bp3.Classes.TEXT_MUTED}>Mittagspause</dt>
          <dd>{lunchBreak}</dd>
          <dt className={bp3.Classes.TEXT_MUTED}>Sporteinheiten</dt>
          <dd>{sportUnits}</dd>
          <dt className={bp3.Classes.TEXT_MUTED}>Tageszeiten</dt>
          <dd>{dayTimes[allowedDayTimes]}</dd>
        </dl>
        <dl>
          <dt className={bp3.Classes.TEXT_MUTED}>Schulleiter</dt>
          <dd>{schoolInfo?.contactName}</dd>
          <dt className={bp3.Classes.TEXT_MUTED}>Telefon</dt>
          <dd><bp3.AnchorButton small minimal style={{ marginLeft: -7 }} intent={bp3.Intent.PRIMARY} href={`tel:${schoolInfo?.phone}`} rightIcon={IconNames.SHARE}>{schoolInfo?.phone}</bp3.AnchorButton></dd>
          <dt className={bp3.Classes.TEXT_MUTED}>E-Mail</dt>
          <dd><bp3.AnchorButton small minimal style={{ marginLeft: -7 }} intent={bp3.Intent.PRIMARY} href={`mailto:${schoolInfo?.email}`} rightIcon={IconNames.SHARE}>{schoolInfo?.email}</bp3.AnchorButton></dd>
          <dt className={bp3.Classes.TEXT_MUTED}>Lehrer</dt>
          <dd>{teachersCount}</dd>
        </dl>
      </div>
      {warnings?.length ? <h2 className="no-print">Probleme</h2> : null}
      {warnings?.map(w => {
        const [a, b] = (w.message as string).split(w.student.name);
        return (
          <bp3.Callout key={w.warningId} intent={bp3.Intent.DANGER} style={{ marginBottom: 7 }} className="no-print">
            {a}
            <bp3.Popover content={<ContextMenu name={w.student.name} />} minimal captureDismiss={true}>
              <bp3.Button
                alignText={bp3.Alignment.LEFT}
                rightIcon={IconNames.CARET_DOWN}
                text={w.student.name}
                style={{ position: 'relative', top: -1 }}
                minimal
                small
              />
            </bp3.Popover>
            {b}
          </bp3.Callout>
        );
      })}
    </section>
  )
}

