import React from 'react';
import * as bp3 from '@blueprintjs/core';
import classNames from 'classnames';

export const Ellipsis = ({ tagName = 'span', children, className, ...props }: any) => (
  React.createElement(tagName, { className: classNames([bp3.Classes.TEXT_OVERFLOW_ELLIPSIS, className]), title: children, ...props }, children)
);

export type LevelVal = 'INTERMEDIATE' | 'BEGINNERS' | 'NONE';

export function level(level: LevelVal) {
  switch (level) {
    case 'NONE': return '';
    case 'BEGINNERS': return 'Beginner';
    case 'INTERMEDIATE': return 'Fortgeschritten';
    default: return null;
  }
}

export const formattedActivityName = (activity: any, noFade = false) => <>
  <span>{activity?.name}</span>
  <span className={noFade ? undefined : bp3.Classes.TEXT_MUTED}>{level(activity?.level) && <>{' – '}{level(activity?.level)}</>}</span>
</>

export const formattedActivityNameWithTime = (slot: any) => <>
  {formattedActivityName(slot.activity, true)}{' '}{slot.start.substr(0, slot.start.length - 3)}
</>

export const formattedActivityNameWithTimeString = (slot: any) => 
  slot && `${slot?.activity?.name}${level(slot.activity?.level) && ` – ${level(slot.activity?.level)}`} ${slot.start.substr(0, slot.start.length - 3)}`;
