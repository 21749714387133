export function* map<X, Y>(xs: Iterable<X>, f: (x: X) => Y): IterableIterator<Y> {
  for (const x of xs) yield f(x);
}

export function groupBy<X, K>(xs: Iterable<X>, f: (x: X) => K): Map<K, X[]> {
  const res = new Map<K, X[]>();
  for (const x of xs) {
    const key = f(x);
    const group = res.get(key) || [];
    res.set(key, group.concat(x));
  }
  return res;
}

export function* unique<X>(xs: Iterable<X>, comp: (a: X, b: X) => boolean = (a, b) => a === b) {
  const arr = [...xs];
  const unq = arr.filter((x, i, self) => self.findIndex(y => comp(x, y)) === i);
  for (const u of unq) yield u;
}

export function* join<X, Y>(xs: Iterable<X>, separator: Y): IterableIterator<X | Y> {
  let skippedFirst = false;
  for (const x of xs) {
    if (skippedFirst) yield separator; else skippedFirst = true;
    yield x;
  }
}

// export function* interleave<X, Y>(xs: Iterable<X>, ys: Iterable<Y>): IterableIterator<X | Y> {
//   const itx = xs[Symbol.iterator]();
//   const ity = ys[Symbol.iterator]();
//   while (true) {
//     const rx = itx.next();
//     if (rx.done) break;
//     else yield rx.value;
//     const ry = ity.next();
//     if (ry.done) break;
//     else yield ry.value;
//   }
// }

// export function* repeat<X>(value: X, r: number = Number.POSITIVE_INFINITY): IterableIterator<X> {
//   for (let i = 0; i < r; i++) yield value;
// }
