import React from 'react';
import * as bp3 from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as im from 'immer';
import * as rc from 'recoil';
import isEqual from 'react-fast-compare';
import { differenceWith } from 'lodash';
import { TimePicker, TimePrecision } from '@blueprintjs/datetime';

import { WithIdSelect } from './StringSelect';
import { StringMultiSelect } from './StringMultiSelect';

import * as state from '../state';
import { api, POST, PATCH, DELETE } from '../api';
import { maybeValue, noop, useRecoilValueLoadable } from '../utils';
import { level, LevelVal } from './common';
import { Link, useLocation } from 'react-router-dom';
import {schools} from "../state";

export type DayStr =
  | 'MONDAY'
  | 'TUESDAY'
  | 'WEDNESDAY'
  | 'THURSDAY'
  | 'FRIDAY'
  | 'SATURDAY'
  | 'SUNDAY';

export const DAY_STRS: DayStr[] = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

export const day2str = (x: DayStr) => {
  switch (x) {
    case 'MONDAY': return 'Mo';
    case 'TUESDAY': return 'Di';
    case 'WEDNESDAY': return 'Mi';
    case 'THURSDAY': return 'Do';
    case 'FRIDAY': return 'Fr';
    case 'SATURDAY': return 'Sa';
    case 'SUNDAY': return 'So';
    default: throw Error();
  }
}

export const str2day = (x: string): DayStr => {
  switch (x) {
    case 'Mo': return 'MONDAY';
    case 'Di': return 'TUESDAY';
    case 'Mi': return 'WEDNESDAY';
    case 'Do': return 'THURSDAY';
    case 'Fr': return 'FRIDAY';
    case 'Sa': return 'SATURDAY';
    case 'So': return 'SUNDAY';
    default: throw Error();
  }
}

export const days2str = (x: DayStr[]) => {
  return `${day2str(x[0])}. – ${day2str(x[x.length - 1])}.`;
}

const idCompare = (a: { id: string }, b: { id: string }) => a.id === b.id;

export function ContextMenu({ name, onRemove }: { name: string, onRemove?: () => void }) {
  return (
    <bp3.Menu >
      <bp3.MenuItem
        text="Kopieren"
        icon={IconNames.DUPLICATE}
        // label="⌘C"
        onClick={() => { navigator.clipboard.writeText(name).catch(err => `Name konnte nicht in die Zwischenabalge kopiert werden! ${err}`) }}
      />
      {onRemove && <bp3.MenuItem
        text="Entfernen"
        icon={IconNames.CUT}
        intent={bp3.Intent.DANGER}
        // label="⌘X"
        onClick={() => {
          navigator.clipboard.writeText(name).catch(err => `Name konnte nicht in die Zwischenabalge kopiert werden! ${err}`);
          onRemove(); }}
      />}
    </bp3.Menu>
  );
}

const weeklySortFn = (a: DayStr, b: DayStr) => Math.sign(DAY_STRS.indexOf(a) - DAY_STRS.indexOf(b))

const s2d = (string: string) => {
  const [hh, mm, ss] = string.split(':').map(Number);
  return new Date(1970, 0, 0, hh, mm, ss);
}

const d2s = (d: Date) => [
  d.getHours().toString().padStart(2, '0'),
  d.getMinutes().toString().padStart(2, '0'),
  d.getSeconds().toString().padStart(2, '0'),
].join(':');

const NONE = { id: null, name: '⸺' };

const dirname = (pathname: string) => pathname.match(/^(.*)\/(.*)$/)?.[1] ?? '';

export function SlotPopoverContent({ slot: origSlot, warning }: { slot: any, warning: any }) {
  const [slot, setSlot] = React.useState<any>(origSlot);
  const touched = React.useMemo(() => !isEqual(origSlot, slot), [origSlot, slot]);

  const [loading1, setLoading] = React.useState(false);
  const [error, setError] = React.useState<any>(null)

  const groupParticipantsBySchool = (slot: any) => {
    const bySchool : any[] = [];
    (slot.participants as any[]).forEach(participant => {
      const school = bySchool.find(s => s.id === participant.school.id);
      if(school) {
        school.participants.push(JSON.parse(JSON.stringify(participant)));
      } else {
        const newSchool = {...participant.school};
        newSchool.participants = [];
        newSchool.participants.push(JSON.parse(JSON.stringify(participant)));
        bySchool.push(newSchool);
      }
    });
    return bySchool;
  };

  const [participantsBySchool, setParticipantsBySchool] = React.useState(groupParticipantsBySchool(slot));

  const { state: allXState, contents: allX } = useRecoilValueLoadable(state.allX);

  const selectionChange = React.useCallback((selection: any[]) => {
    setSlot((slot: any) => im.produce(slot, (slot: any) => { slot.days = selection.map(str2day).sort(weeklySortFn) }));
  }, []);

  const timeChange = React.useCallback((startOrEnd: 'start' | 'end') => (e: Date) => {
    setSlot((slot: any) => im.produce(slot, (slot: any) => { slot[startOrEnd] = d2s(e) }));
  }, []);

  const teacherNameChange = React.useCallback((teacherName: string) => {
    setSlot((slot: any) => im.produce(slot, (slot: any) => { slot.teacherName = teacherName }));
  }, []);

  const locationNameChange = React.useCallback((locationName: string) => {
    setSlot((slot: any) => im.produce(slot, (slot: any) => { slot.locationName = locationName }));
  }, []);

  const withIdChange = React.useCallback((key: 'teacher' | 'location' | 'activity') => (selection: any) => {
    setSlot((slot: any) => im.produce(slot, (slot: any) => { slot[key] = selection !== NONE ? selection : null }));
  }, []);

  const limitChange = React.useCallback(([from, to]: [number, number]) => {
    setSlot((slot: any) => im.produce(slot, (slot: any) => { slot.participantLimit = { from, to } }));
  }, []);

  const infoTextChange = React.useCallback((infoText: string) => {
    setSlot((slot: any) => im.produce(slot, (slot: any) => { slot.description.infoText = infoText; }))
  }, []);

  const slugLineChange = React.useCallback((slugLine: string) => {
    setSlot((slot: any) => im.produce(slot, (slot: any) => { slot.description.slugLine = slugLine; }))
  }, []);

  const keyPress = React.useCallback((school: any) => {
    return (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && e.currentTarget.value.trim() !== '') {
        setSlot((slot: any) => im.produce(slot, (slot: any) => {
          slot.participants.push({ name: e.currentTarget.value, school })
          e.currentTarget.value = '';
          setParticipantsBySchool(groupParticipantsBySchool(slot));
        }));
      }
    }
  }, []);

  const remove = React.useCallback((participant: any) => {
    return () => {
      setSlot((slot: any) => im.produce(slot, (slot: any) => {
        slot.participants = slot.participants.filter((p: any) => p.id !== participant.id);
        setParticipantsBySchool(groupParticipantsBySchool(slot));
      }));
    };
  }, [setParticipantsBySchool]);

  const reloadSlots = rc.useRecoilCallback(({ snapshot }) => {
    const [year, week] = maybeValue(snapshot.getLoadable(state.yearWeek).contents) ?? [0, 0];
    if (!year || !week) return noop;
    (window as any).__scrollPos = window.pageYOffset;
    new BroadcastChannel('activity-planner').postMessage({ year, week });
    return noop;
  }, []);

  const uploadChanges = React.useCallback(async () => {
    const { start, end, days, participantLimit, activity, teacherName, locationName, description } = slot;
    const { infoText, slugLine } = description;

    const slotUpdateData = {
      ...start !== origSlot.start ? { start } : {},
      ...end !== origSlot.end ? { end } : {},
      ...!isEqual(days, origSlot.days) ? { days } : {},
      ...!isEqual(participantLimit, origSlot.participantLimit) ? { participantLimit } : {},
      ...activity?.id !== origSlot.activity?.id ? { activityId: activity?.id ?? null } : {},
      ...teacherName !== origSlot.teacherName ? { teacherName: teacherName ?? null } : {},
      ...locationName !== origSlot.locationName ? { locationName: locationName ?? null } : {},
      "description": {
        ...infoText !== origSlot.description.infoText ? { infoText: infoText ?? null } : {},
        ...slugLine !== origSlot.description.slugLine ? { slugLine: slugLine ?? null } : {}
      }
    };

    const toAdd = differenceWith(slot.participants, origSlot.participants, idCompare).map(({ name, school }: any) => ({
      name,
      schoolId: school.id
    }));

    const toRemove = differenceWith(origSlot.participants, slot.participants, idCompare).map(x => x.id);

    const requests: Request[] = [];
    if (Object.keys(slotUpdateData).length > 0) {
      requests.push(api(`/api/v1/slots/${slot.id}`, { method: PATCH, body: slotUpdateData }));
    }
    if (toAdd.length > 0) {
      requests.push(api(`/api/v1/slots/${slot.id}/students`, { method: POST, body: toAdd }));
    }
    if (toRemove.length > 0) {
      requests.push(api(`/api/v1/slots/${slot.id}/students`, { method: DELETE, body: toRemove }));
    }

    if (requests.length > 0) setLoading(true);

    try {
      const responses = await Promise.all(requests.map(r => fetch(r)));
      if (responses.every(r => r.ok)) {
        reloadSlots();
      } else {
        setLoading(false);
        setError({})
      }
    } catch {
      setLoading(false);
      setError({})
    }
  }, [origSlot, slot, reloadSlots]);

  const deleteSlot = React.useCallback(async () => {
    setLoading(true);
    const response = await fetch(api(`/api/v1/slots/${slot.id}`, { method: DELETE }));
    if(response.ok) {
      reloadSlots();
    } else {
      setLoading(false);
      setError({});
    }
  }, [slot, reloadSlots]);

  // const contactBtn = (
  //   <bp3.AnchorButton intent={bp3.Intent.DANGER} href={`mailto:${'to@do.todo'}`}>Schule Kontaktieren</bp3.AnchorButton>
  // );

  const saveBtn = (
    <bp3.Button
      intent={bp3.Intent.PRIMARY}
      disabled={!touched}
      title={touched ? '' : 'Keine Änderungen vorhanden'}
      onClick={uploadChanges}
    >Speichern</bp3.Button>
  );

  const deleteBtn = (
      <bp3.Button
        intent={bp3.Intent.DANGER}
        title={'Löschen'}
        onClick={deleteSlot}
        >Löschen</bp3.Button>
  );

  const { participantLimit: limit } = slot;

  const loading = loading1 || allXState === 'loading';
  const pathname = useLocation().pathname;

  const { contents: allSchools } = useRecoilValueLoadable(schools);
  const [newSchool, setNewSchool] = React.useState<any>({});
  React.useEffect(() => {
    const school = allSchools?.filter(s => !(participantsBySchool.find(pbs => pbs.id === s.id)))[0];
    if(school) {
      setNewSchool({
        id: school.id,
        schoolName: school.schoolInfo.schoolName
      });
    }
  }, [allSchools, participantsBySchool, setNewSchool]);

  const addSchool = React.useCallback(async () => {
    setParticipantsBySchool([...participantsBySchool, {
      id: newSchool.id,
      name: newSchool.schoolName,
      participants: []
    }]);
  }, [newSchool, participantsBySchool]);

  return (
    <div className="App-popover">
      {allXState === 'hasError' && <div className={bp3.Classes.DIALOG_BODY} style={{ minHeight: 400, display: 'flex', flexDirection: 'column', placeContent: 'center' }}>
        <bp3.NonIdealState
          icon={IconNames.ERROR}
          description={<>
            <div>Basisdaten konnten nicht geladen werden. Bitte versuchen Sie die Seite neu zu laden:</div>
            <bp3.Button icon={IconNames.REPEAT} style={{ marginTop: 15 }} onClick={() => window.location.reload()}>Seite neu laden</bp3.Button>
          </>}
        />
      </div>}
      {allXState !== 'hasError' && <><div className={bp3.Classes.DIALOG_BODY}>
        <section className="App-popover-base">
          {/* <div style={{ marginBottom: 15, fontSize: 16 }}>
            <strong>{slot.activity.name}</strong>
          </div> */}
          <WithIdSelect<{ id: string, name: string, level: LevelVal }>
            style={{ marginBottom: 15, fontSize: 16, fontWeight: 'bold' }}
            disabled={loading}
            activeItem={slot.activity}
            items={allX?.activities ?? []}
            onItemSelect={withIdChange('activity')}
            labelRenderer={x => level(x.level) ?? ''}
            minimal
            fill
          />
          <bp3.FormGroup label={'Ort:'} className={bp3.Classes.TEXT_MUTED} >
            <bp3.InputGroup
              disabled={loading}
              value={slot.locationName ?? ''}
              onChange={(event: any) => locationNameChange(event.currentTarget.value)} />
          </bp3.FormGroup>
          <bp3.ControlGroup>
            <bp3.FormGroup label={'Start:'} className={bp3.Classes.TEXT_MUTED}>
              <TimePicker
                disabled={loading}
                value={s2d(slot.start)}
                precision={TimePrecision.MINUTE}
                onChange={timeChange('start')}
              />
            </bp3.FormGroup>
            <bp3.FormGroup label={'Ende:'} className={bp3.Classes.TEXT_MUTED} style={{ marginLeft: 10 }}>
              <TimePicker
                disabled={loading}
                value={s2d(slot.end)}
                precision={TimePrecision.MINUTE}
                onChange={timeChange('end')}
              />
            </bp3.FormGroup>
          </bp3.ControlGroup>
          <bp3.FormGroup label={'Tage:'} className={bp3.Classes.TEXT_MUTED} >
            <StringMultiSelect
              disabled={loading}
              items={['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So']}
              selection={slot.days.map(day2str)}
              onSelectionChange={selectionChange}
              fill
            />
          </bp3.FormGroup>
          <bp3.FormGroup label={'Lehrer:'} className={bp3.Classes.TEXT_MUTED} >
              <bp3.InputGroup
                disabled={loading}
                value={slot.teacherName ?? ''}
                onChange={(event: any) => {teacherNameChange(event.currentTarget.value)}} />
          </bp3.FormGroup>
          <bp3.FormGroup label={'Anzahl:'} className={bp3.Classes.TEXT_MUTED} >
            <bp3.RangeSlider
              disabled={loading}
              value={[limit.from, limit.to]}
              onChange={limitChange}
              intent={slot.shortOfParticipants || slot.exceedsParticipantLimit ? bp3.Intent.DANGER : bp3.Intent.PRIMARY}
              min={0}
              max={26}
              labelStepSize={2}
            />
          </bp3.FormGroup>
          <bp3.FormGroup label={'Text (oben):'} className={bp3.Classes.TEXT_MUTED} >
            <bp3.InputGroup
                disabled={loading}
                value={slot.description.infoText ?? ''}
                onChange={(event: any) => infoTextChange(event.currentTarget.value)} />
          </bp3.FormGroup>
          <bp3.FormGroup label={'Text (unten):'} className={bp3.Classes.TEXT_MUTED} >
            <bp3.InputGroup
                disabled={loading}
                value={slot.description.slugLine ?? ''}
                onChange={(event: any) => slugLineChange(event.currentTarget.value)} />
          </bp3.FormGroup>
        </section>
        {warning && <bp3.Callout intent={bp3.Intent.DANGER} style={{ marginBottom: 5 }}>
          <div>{warning.message}</div>
          {/* {contactBtn} */}
        </bp3.Callout>}
        <section className="App-popover-schools">
          {participantsBySchool.map((school: any, i) => (
            <bp3.FormGroup key={school.id} label={`Schule ${i + 1}:`} className={bp3.Classes.TEXT_MUTED} >
              <div style={{ /* margin: '6px 7.5px' */ margin: '6px 0' }} className="space-between">
                <strong>{school.name}</strong> {/* <bp3.EditableText defaultValue={participants[0].school.name} /> */}
                <span className={bp3.Classes.TEXT_MUTED}>{school.participants.length} Schüler</span>
              </div>
              {school.participants.map((p: any) => (
                <bp3.Popover key={p.name} content={<ContextMenu name={p.name} onRemove={remove(p)} />} fill minimal captureDismiss={true}>
                  <bp3.Button
                    disabled={loading}
                    alignText={bp3.Alignment.LEFT}
                    rightIcon={IconNames.CARET_DOWN}
                    text={p.name}
                    minimal
                    small
                    fill
                  />
                </bp3.Popover>
                // <div className="space-between" style={{ alignItems: 'center', maxWidth: 300  }}>
                //   <Ellipsis>{p.name}</Ellipsis>
                //   <bp3.Button minimal intent={bp3.Intent.DANGER} icon={IconNames.TRASH} small title="Entfernen" onClick={remove(p)}></bp3.Button>
                // </div>
              ))}
              <bp3.ControlGroup style={{ marginTop: 3 }}>
                <bp3.InputGroup placeholder={"Schüler hinzufügen (Enter ⏎)"} fill small onKeyPress={keyPress(school)} disabled={loading} />
                {/* <bp3.Button icon={IconNames.ADD} small></bp3.Button> */}
              </bp3.ControlGroup>
            </bp3.FormGroup>
          ))}
          <bp3.ControlGroup style={{ marginTop: 3 }}>
            <bp3.HTMLSelect
                options={allSchools?.filter(school => !(participantsBySchool.find(pbs => pbs.id === school.id)))?.map(school => school?.schoolInfo?.schoolName)}
                value={newSchool?.schoolName}
                onChange={e => {
                  const schoolName = e.target.value;
                  const school = allSchools?.find(school => school.schoolInfo.schoolName === schoolName);
                  setNewSchool({
                    id: school.id,
                    schoolName: schoolName
                  });
                }}
                disabled={loading}
                fill />
            <bp3.Button
                icon={IconNames.ADD}
                onClick={addSchool}
                small>
            </bp3.Button>
          </bp3.ControlGroup>
        </section>
      </div>
      <div className={bp3.Classes.DIALOG_FOOTER}>
        {error && <bp3.Callout intent={bp3.Intent.DANGER} style={{ marginBottom: 15, marginTop: -15 }}>Änderungen konnten nicht gespeichert werden! Bitte versuchen Sie es erneut!</bp3.Callout>}
        <div className={bp3.Classes.DIALOG_FOOTER_ACTIONS}>
          <Link to={dirname(pathname)}><bp3.AnchorButton>Abbrechen</bp3.AnchorButton></Link>
          {saveBtn}
          {deleteBtn}
        </div>
      </div></>}
    </div>
  )
}