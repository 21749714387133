import React, { Dispatch, SetStateAction } from 'react';

import * as bp3 from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import classNames from 'classnames';
import * as rc from 'recoil';
import * as df from 'date-fns';

import { api, DELETE, POST } from '../api';
import * as it from '../iter';
import { darkModeState, weeklyString } from '../utils';

export function UploadDialog({ isOpen, setIsOpen }: { isOpen: boolean, setIsOpen: Dispatch<SetStateAction<boolean>> }) {
  const [complete, setIsComplete] = React.useState(false);

  const [darkMode] = rc.useRecoilState(darkModeState);
  const [file, setFile] = React.useState<File | null>();
  const [bookings, setBookings] = React.useState<any[] | null>(null);
  const [error, setError] = React.useState<null | { type: 'bookings' | 'confirmation' }>(null);

  const upload = React.useCallback(async (newFile?: File | undefined) => {
    try {
      const f = newFile || file;
      if (!f) return;

      if (newFile) setFile(newFile);
      setBookings(null);

      const body = new FormData();
      body.append('bookingFile', f);

      const res = await fetch(api(`/api/v1/bookings`, { method: POST, body }));
      if (res.status === 201) {
        setBookings(await res.json())
        setError(null);
      } else {
        setError({ type: 'bookings' });
      }
    } catch (e) {
      setError({ type: 'bookings' });
    }
  }, [file]);

  const confirm = React.useCallback(async () => {
    try {
      const res = await fetch(api(`/api/v1/bookings/confirmation`, { method: POST, body: bookings?.map(x => x.id) }));
      if (res.ok) {
        setIsComplete(true);
        setError(null);
      } else {
        setError({ type: 'confirmation' });
      }
    } catch (e) {
      setError({ type: 'confirmation' });
    }
  }, [bookings]);


  const abort = React.useCallback(() => {
    setFile(null);
    setError(null);
    setBookings(null);
    setIsComplete(false);
    setIsOpen(false);
  }, [setIsOpen]);

  const finalize = React.useCallback(() => {
    // Mark modified weeks as dirty by updating the request ids
    const bc = new BroadcastChannel('activity-planner');
    (bookings ?? [])
      .map(({ bookingTime: { to } }) => new Date(to))
      .map(d => [df.getISOWeekYear(d), df.getISOWeek(d)])
      .forEach(([year, week]) => bc.postMessage({ year, week }));
    abort(); // Reset dialog state
  }, [bookings, abort]);

  const deleteUnconfirmed = React.useCallback(async (id: string) => {
    try {
      if (!bookings?.length) return;
      setBookings(bookings.filter(x => x.id !== id));
      const res = await fetch(api(`/api/v1/bookings`, { method: DELETE, body: [id] }));
      if (!res.ok) { setBookings(bookings) }
    } catch { setBookings(bookings) }
  }, [bookings]);

  return (
    <bp3.Dialog
      className={classNames({ 'bp4-dark': darkMode })}
      icon={IconNames.UPLOAD}
      title="Neue Buchung"
      onClose={abort}
      isOpen={isOpen}
      canOutsideClickClose={false}
    >
      {!!error && <div className={bp3.Classes.DIALOG_BODY} style={{ minHeight: 400, display: 'flex', flexDirection: 'column', placeContent: 'center' }}>
        <bp3.NonIdealState
          icon={IconNames.ERROR}
          title={error.type === 'bookings' ? 'Upload fehlgeschlagen!' : 'Bestätigung fehlgeschlagen!'}
          description={<>
            {error.type === 'confirmation' 
              ? <div>Die Buchung für {[...it.join(bookings?.map(b => <em key={b.id}>{b.schoolInfo.schoolName}</em>) ?? [], ', ')]} konnte nicht bestätigt werden. Bitte versuchen Sie es erneut:</div>
              : <div>Bitte versuchen Sie es erneut:</div>}
            <bp3.Button icon={IconNames.REPEAT} style={{ marginTop: 15 }} onClick={() => { setError(null); if (error.type === 'bookings') upload() }}>Erneut versuchen</bp3.Button>
          </>}
        />
      </div>}
      {!error && complete && <>
        <div className={bp3.Classes.DIALOG_BODY} style={{ minHeight: 400, display: 'flex', flexDirection: 'column', placeContent: 'center' }}>
          <bp3.NonIdealState
            icon={IconNames.ENDORSED}
            title="Buchung Hinzugefügt"
            description={<>
              Die Buchung für {[...it.join(bookings?.map(b => <em key={b.id}>{b.schoolInfo.schoolName}</em>) ?? [], ', ')]} wurde erfolgreich zum Buchungssystem hinzugefügt.
              </>}
          />
        </div>
        <div className={bp3.Classes.DIALOG_FOOTER}>
          <div className={bp3.Classes.DIALOG_FOOTER_ACTIONS}>
            <bp3.Button onClick={finalize}>Schließen</bp3.Button>
          </div>
        </div>
      </>}
      {!error && !complete && <>
        <div className={bp3.Classes.DIALOG_BODY} style={{ minHeight: 400 }}>
          <bp3.H4>Excel Datei Hochladen</bp3.H4>
          <bp3.FileInput
            text={file?.name || "Keine Datei ausgewählt"}
            hasSelection={!!file?.name}
            onInputChange={(event) => { upload(event.currentTarget.files?.[0]) }}
            large
            fill
          />
          {file?.name && <>
            <bp3.H4 style={{ marginTop: 25 }}>Buchungsdaten</bp3.H4>
            {bookings?.map(booking => (
              <div key={booking.id} style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: 5 }}>{booking.schoolInfo.schoolName}</span>
                <span className={bp3.Classes.TEXT_MUTED}>{weeklyString(booking.bookingTime)}</span>
                <bp3.Button
                  intent={bp3.Intent.DANGER}
                  icon={IconNames.TRASH}
                  style={{ marginLeft: 'auto' }}
                  onClick={() => deleteUnconfirmed(booking.id)}
                  minimal
                >Entfernen</bp3.Button>
              </div>
            )) ?? <bp3.Spinner size={bp3.SpinnerSize.SMALL} />}
          </>}
        </div>
        <div className={bp3.Classes.DIALOG_FOOTER}>
          <div className={bp3.Classes.DIALOG_FOOTER_ACTIONS}>
            <bp3.Button onClick={abort}>Abbrechen</bp3.Button>
            <bp3.Button intent={bp3.Intent.PRIMARY} disabled={!bookings?.length} onClick={confirm}>Buchung hinzufügen</bp3.Button>
          </div>
        </div>
      </>}
    </bp3.Dialog>
  );
}
