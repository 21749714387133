import React, {useState} from 'react';
import * as bp4 from '@blueprintjs/core';
import {IconNames} from "@blueprintjs/icons";

export default function ErrorState({show = false, error = null}: { show: boolean, error: string | null }) {
    const [copied, setCopied] = useState<boolean>(false);
    if (show)
        return <bp4.NonIdealState icon={IconNames.ERROR}
                                  title="Fehler!"
                                  description={<>
                                      Es ist ein unbekannter Fehler aufgetreten. Bitte kopieren Sie die
                                      Fehlermeldung{' '}
                                      und machen Sie einen Screenshot der Seite. Senden Sie die Informationen an{' '}
                                      <a href="mailto:ap@partschi.com">Ihren Administrator</a>.
                                      <br/>
                                      <br/>
                                      <bp4.InputGroup
                                          fill
                                          value={error || 'FEHLER!'}
                                          disabled={true}
                                          rightElement={<bp4.Button
                                              small
                                              icon={IconNames.CLIPBOARD}
                                              intent={copied? bp4.Intent.SUCCESS : bp4.Intent.NONE}
                                              onClick={async () => {
                                                  try {
                                                      await navigator.clipboard.writeText(error || '');
                                                      setCopied(true);
                                                  } catch (e) {
                                                      alert(`Inhalt konnte nicht in die Zwischenablage kopiert werden! ${e}`);
                                                  }
                                              }}/>}/>
                                  </>}/>;
    return <></>;
}