import React, {useState} from 'react';
import * as bp4 from '@blueprintjs/core';
import {IconNames} from "@blueprintjs/icons";
import {Formik} from 'formik';
import {api} from "../api";
import ErrorState from "./ErrorState";

export default function NewBookingDialog({
                                             isOpen = false,
                                             close = () => {
                                             },
                                             yearWeek: {year = '0', week = '1'}
                                         }: {
    isOpen: boolean,
    close: () => void,
    yearWeek: { year: string, week: string }
}) {
    const generateTestData = () => {
        return {
            schoolInfo: {
                schoolName: 'HTBLuVA Spengergasse, Wien V',
                contactName: 'Ing. Klaus Unger',
                phone: '+43 572890 123 1',
                email: 'unger@example.org'
            },
            teachersCount: 2,
            sportsUnit: 16,
            yearWeek: {
                year: year,
                weekNumber: parseInt(week)
            }
        };
    };
    const [error, setError] = useState<string | null>(null);
    return <bp4.Dialog isOpen={isOpen}
                       onClose={close}
                       icon={IconNames.NEW_LINK}
                       title={`Neue Buchungen in ${year}/${week}`}
                       style={{width: 'fit-content'}}>
        <Formik initialValues={{
            schoolInfo: {
                schoolName: '',
                contactName: '',
                phone: '',
                email: ''
            },
            teachersCount: 1,
            sportsUnit: 10,
            yearWeek: {
                year: year,
                weekNumber: parseInt(week)
            }
        }} onSubmit={async (values, {resetForm}) => {
            try {
                const response = await fetch(api(`/api/v1/bookings/new`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": 'application/json'
                    },
                    body: JSON.stringify(values)
                }));
                if(response.status !== 201) {
                    setError(`HTTP Error ${response.status}: ${await response.text()}`);
                } else {
                    resetForm();
                    close();
                }
            } catch(e) {
                setError(`Unknown Error: ${e}`);
            }
        }}>
            {({
                handleSubmit,
                isSubmitting,
                values,
                setFieldValue,
                setValues
              }) =>
                <>
                    <form onSubmit={handleSubmit}>
                        <div className={bp4.Classes.DIALOG_BODY}>
                            <ErrorState show={error !== null && error !== ''} error={error} />
                            <br />
                            <bp4.FormGroup label="Schulname" labelInfo={"(*)"}>
                                <bp4.InputGroup
                                    fill
                                    required
                                    minLength={3}
                                    maxLength={256}
                                    disabled={isSubmitting}
                                    placeholder="HTL Wien..."
                                    value={values.schoolInfo.schoolName}
                                    onChange={({currentTarget: {value}}) =>
                                        setFieldValue('schoolInfo.schoolName', value)}/>
                            </bp4.FormGroup>
                            <bp4.FormGroup label="Anzahl Lehrpersonen" labelInfo="(*)">
                                <bp4.NumericInput
                                    fill
                                    required
                                    min={1}
                                    max={100}
                                    disabled={isSubmitting}
                                    value={values.teachersCount}
                                    onValueChange={value => setFieldValue('teachersCount', value)} />
                            </bp4.FormGroup>
                            <bp4.Divider />
                            <bp4.FormGroup label="Kontaktname" labelInfo={"(*)"}>
                                <bp4.InputGroup
                                    fill
                                    required
                                    minLength={3}
                                    maxLength={256}
                                    disabled={isSubmitting}
                                    placeholder="Max Mustermann"
                                    value={values.schoolInfo.contactName}
                                    onChange={({currentTarget: {value}}) =>
                                        setFieldValue('schoolInfo.contactName', value)}/>
                            </bp4.FormGroup>
                            <bp4.FormGroup label="Telefon" labelInfo={"(*)"}>
                                <bp4.InputGroup
                                    fill
                                    required
                                    minLength={3}
                                    maxLength={256}
                                    disabled={isSubmitting}
                                    placeholder="+43 123 456 78"
                                    value={values.schoolInfo.phone}
                                    onChange={({currentTarget: {value}}) =>
                                        setFieldValue('schoolInfo.phone', value)}/>
                            </bp4.FormGroup>
                            <bp4.FormGroup label="E-Mail" labelInfo={"(*)"}>
                                <bp4.InputGroup
                                    fill
                                    required
                                    minLength={3}
                                    maxLength={256}
                                    disabled={isSubmitting}
                                    placeholder="max.mustermann@example.org"
                                    value={values.schoolInfo.email}
                                    onChange={({currentTarget: {value}}) =>
                                        setFieldValue('schoolInfo.email', value)}/>
                            </bp4.FormGroup>
                            <bp4.Divider />
                            <bp4.FormGroup label="Sporteinheiten" labelInfo="(*)">
                                <bp4.NumericInput
                                    fill
                                    required
                                    min={1}
                                    max={100}
                                    disabled={isSubmitting}
                                    value={values.sportsUnit}
                                    onValueChange={value => setFieldValue('sportsUnit', value)} />
                            </bp4.FormGroup>
                        </div>
                        <div className={bp4.Classes.DIALOG_FOOTER}>
                            <div className={bp4.Classes.DIALOG_FOOTER_ACTIONS}>
                                <bp4.ButtonGroup>
                                    <bp4.Button
                                        type="submit"
                                        icon={IconNames.SAVED}
                                        intent={bp4.Intent.SUCCESS}
                                        text="Speichern" />
                                    <bp4.Button
                                        icon={IconNames.TRASH}
                                        intent={bp4.Intent.DANGER}
                                        text="Abbrechen"
                                        onClick={() => {
                                            setError(null);
                                            close();
                                        }} />
                                    <bp4.Button
                                        icon={IconNames.LAB_TEST}
                                        intent={bp4.Intent.WARNING}
                                        text="Test Daten"
                                        onClick={() => setValues(generateTestData())} />
                                </bp4.ButtonGroup>
                            </div>
                        </div>
                    </form>
                </>}
        </Formik>
    </bp4.Dialog>;
}