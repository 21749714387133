import React from 'react';
import { useLocation } from 'react-router-dom';
import * as rc from 'recoil';
import * as df from 'date-fns';
import { UUID } from 'uuid-class';
import { Base64Decoder, Base64Encoder } from 'base64-encoding';

const DARK_MODE_MQ = '(prefers-color-scheme: dark)';

export const darkModeState = rc.atom({
  key: 'darkMode',
  default: window.matchMedia(DARK_MODE_MQ).matches,
});

function addMediaListener(mq: MediaQueryList, cb: (this: MediaQueryList, e: MediaQueryListEvent) => any) {
  if ('addEventListener' in MediaQueryList.prototype) {
    mq.addEventListener('change', cb);
  } else {
    mq.addListener(cb);
  }
}

export function useDarkMode() {
  const [d, sd] = rc.useRecoilState(darkModeState);
  React.useEffect(() => {
    addMediaListener(window.matchMedia(DARK_MODE_MQ), (e) => sd(e.matches));
  }, [sd]);
  return [d, sd] as [boolean, rc.SetterOrUpdater<boolean>];
}

// export function useFetch<T>(resource: RequestInfo, init?: RequestInit, options: ra.FetchOptions<T> = {}) {
//   const request = new Request(resource, init);
//   if (request.headers.get('accept')?.includes('json')) options.json = true;
//   return ra.useFetch(request, {}, options);
// }

export const noop = () => { };

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function maybeValue<X>(loadable: X | Promise<any> | Error | null) {
  return loadable instanceof Error || loadable instanceof Promise ? null : loadable;
}

export function useRecoilValueLoadable<T>(recoilValue: rc.RecoilValue<T>): Omit<rc.Loadable<T>, 'contents'> & { contents: T | null } {
  const { contents, ...args } = rc.useRecoilValueLoadable(recoilValue)
  return { ...args, contents: maybeValue(contents) };
}

const dayFormatter = new Intl.DateTimeFormat('de-AT', { day: 'numeric' })
const datFormatter = new Intl.DateTimeFormat('de-AT', { year: 'numeric', month: 'long', day: 'numeric' })

export const weeklyString = ({ from, to }: { from: string | number | Date, to: string | number | Date }) => [
  dayFormatter.format(new Date(from)),
  '. – ',
  datFormatter.format(new Date(to)),
].join('');

export function startOfISOWeekFromYearWeek(year: number, week: number) {
  let dt = df.setISOWeekYear(new Date(), year);
  dt = df.setISOWeek(dt, week);
  dt = df.startOfISOWeek(dt);
  return dt;
}

export function endOfISOWeekFromYearWeek(year: number, week: number) {
  let dt = df.setISOWeekYear(new Date(), year);
  dt = df.setISOWeek(dt, week);
  dt = df.endOfISOWeek(dt);
  return dt;
}

export const shortenId = (id: string | null) => id && new Base64Encoder({ url: true }).encode(new UUID(id));
export const elongateId = (id: string | null) => id && new UUID(new Base64Decoder().decode(id)).toString();

export const translateLevel = (level: string) => {
  switch (level) {
    case 'BEGINNERS': return 'Anfänger';
    case 'INTERMEDIATE': return 'Fortgeschritten';
    default: return '';
  }
};
