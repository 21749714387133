import * as rc from 'recoil';
import * as df from 'date-fns';

import { api } from './api';
import * as it from './iter';

const filename = (x: string) => x.match(/^.*\/(.*)$/)?.[1] ?? null;

// type WithName = { name: string };
// const nameCompareFn = (a: WithName, b: WithName) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
// const nameEqualFn = (a: WithName, b: WithName) => a.name === b.name;
const slotCompareFn = (a: { start: string, end: string }, b: { start: string, end: string }) => {
  const an = Number(a.start.replace(/:/g, ''));
  const bn = Number(b.start.replace(/:/g, ''));
  const rs = Math.sign(an - bn);
  if (rs !== 0) return rs;
  {
    const an = Number(a.end.replace(/:/g, ''));
    const bn = Number(b.end.replace(/:/g, ''));
    return Math.sign(an - bn);
  }
}

export const time = rc.atom({
  key: 'time',
  default: df.startOfISOWeek(Date.now()).getTime(),
});

export const requestId = rc.atomFamily({
  key: 'requestId',
  default: (_: [number, number]) => 0,
});


export const date = rc.selector({
  key: 'date',
  get: ({ get }) => new Date(get(time)),
});

export const week = rc.selector({
  key: 'week',
  get: ({ get }) => df.getISOWeek(get(time)),
})

export const year = rc.selector({
  key: 'year',
  get: ({ get }) => df.getISOWeekYear(get(time)),
})

export const yearWeek = rc.selector({
  key: 'yearWeek',
  get: ({ get }) => [get(year), get(week)] as [number, number],
});

export const currentRequestId = rc.selector({
  key: 'currentRequestId',
  get: ({ get }) => {
    const id = get(requestId(get(yearWeek)));
    return id;
  }
});

export function useRefreshYearWeek([year, week]: [number, number]) {
  const setYearWeekRequestId = rc.useSetRecoilState(requestId([year, week]));
  return () => setYearWeekRequestId(id => id + 1);
}

export const startOfWeekTime = rc.selector({
  key: 'startOfWeekTime',
  get: ({ get }) => df.startOfISOWeek(get(time)).getTime(),
})

export const allX = rc.selector({
  key: 'allX',
  get: async () => {
    const resX = await Promise.all([
      fetch(api(`/api/v1/activities`))
    ]);
    if (resX.every(r => r.ok)) {
      return Object.fromEntries(await Promise.all(resX.map(async x => [filename(x.url), await x.json()])));
    } else {
      throw Error();
    }
  },
});

export const activities = rc.selector({
  key: 'activities',
  get: async ({ get }) => {
    get(currentRequestId);
    const [yyyy, ww] = get(yearWeek);
    const res = await fetch(api(`/api/v1/plans/${yyyy}/${ww}/activities`));
    if (res.ok) {
      return await res.json() as any[];
    } else {
      throw Error(await res.text());
    }
  },
});

export const activitiesForFilter = rc.selector({
  key: 'activitiesForFilter',
  get: ({ get }) => [...it.unique(get(activities).map(x => x.name).sort())],
});

export const schools = rc.selector({
  key: 'schools',
  get: async ({ get }) => {
    get(currentRequestId);
    const [yyyy, ww] = get(yearWeek);
    const res = await fetch(api(`/api/v1/plans/${yyyy}/${ww}/schools`));
    if (res.ok) {
      return await res.json() as any[];
    } else {
      throw Error(await res.text());
    }
  },
});

export const activityById = rc.selectorFamily({
  key: 'activityById',
  get: (id: string) => ({ get }) => get(activities)?.find((a: any) => a.id === id),
});

export const slotById = rc.selectorFamily({
  key: 'slotById',
  get: (id: string) => ({ get }) => get(currentSlots)?.find((a: any) => a.id === id),
});

export const schoolById = rc.selectorFamily({
  key: 'schoolById',
  get: (id: string) => ({ get }) => get(schools)?.find((a: any) => a.id === id),
});

export const currentSlots = rc.selector({
  key: 'slots',
  get: async ({ get }) => {
    get(currentRequestId);
    const [yyyy, ww] = get(yearWeek);
    const res = await fetch(api(`/api/v1/plans/${yyyy}/${ww}/slots`));
    if (res.ok) {
      return (await res.json() as any[]).sort(slotCompareFn);
    } else {
      throw Error(await res.text());
    }
  },
});

export const totalWeeklyParticipants = rc.selector({
  key: 'totalWeeklyParticipants',
  get: ({ get }) =>{
    const slots = get(currentSlots);
    return [...new Set(slots.flatMap(slot => slot.participants).map(participant => participant.name))].length;
  },
});

export const slotsByActivity = rc.selector({
  key: 'slotsByActivity',
  get: ({ get }) => {
    const slots = get(currentSlots);
    const as = get(activities);
    const groups = it.groupBy(slots as any[], s => s.activity.id);
    return [...groups].map(([id, slots]) => ({
      activity: as.find(a => a.id === id),
      slots,
    }));
  },
})

export const filteredSlotsByActivity = rc.selectorFamily({
  key: 'filteredSlotsByActivity',
  get: (sportFilter: string | null) => ({ get }) => {
    const slotsAndActivities = get(slotsByActivity);
    return slotsAndActivities.filter(({ activity: a }) => !sportFilter || a?.name === sportFilter);
  },
});

export const slotsBySchool = rc.selector({
  key: 'slotsBySchool',
  get: ({ get }) => {
    const slots = get(currentSlots);
    return get(schools).map(s => ({
      school: s,
      slots: slots.filter(slot => (slot.participants as any[]).some(p => p.school.id === s.id)),
    }));
  },
})

export const filteredSlotsBySchool = rc.selectorFamily({
  key: 'filteredSlotsBySchool',
  get: (schoolFilter: string | null) => ({ get }) => {
    const slotsAndActivities = get(slotsBySchool);
    return slotsAndActivities.find(({ school: s }) => !schoolFilter || s?.id === schoolFilter) ?? null;
  },
})


export const warnings = rc.selector({
  key: 'warnings',
  get: async ({ get }) => {
    const [yyyy, ww] = get(yearWeek);
    get(currentRequestId);
    const res = await fetch(api(`/api/v1/warnings/${yyyy}/${ww}`));
    if (res.ok) {
      return (await res.json() as any[])
    } else {
      throw Error(await res.text());
    }
  },
});

export const warningBySlot = rc.selectorFamily({
  key: 'warningBySlot',
  get: (id: string) => ({ get }) => {
    return get(warnings).find(w => w.id === id);
  },
});

export const warningsBySchool = rc.selectorFamily({
  key: 'warningsBySchool',
  get: (id: string) => ({ get }) => {
    return get(warnings).filter(w => w.student?.school?.id === id);
  },
});