import { JSONRequest, JSONRequestInit, ParamsURL } from '@werker/json-fetch';

const API = process.env.REACT_APP_API || window.location.origin;

export const GET = 'GET';
export const HEAD = 'HEAD';
export const POST = 'POST';
export const PUT = 'PUT';
export const DELETE = 'DELETE';
export const CONNECT = 'CONNECT';
export const OPTIONS = 'OPTIONS';
export const TRACE = 'TRACE';
export const PATCH = 'PATCH';

export const api = (pathname: string, init?: JSONRequestInit & { params?: Record<string, string> }) => {
  const url = new ParamsURL(pathname, init?.params ?? {}, API);
  const request = new JSONRequest(url, init);
  // request.headers.append('Authorization', auth);
  return request;
}
