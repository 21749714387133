import React from "react";
import * as bp3 from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Select, ItemRenderer, ItemPredicate, ISelectProps, ICreateNewItem } from '@blueprintjs/select';

const abs0000: React.CSSProperties = { position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 };

/*export function StringSelect({ items, activeItem, disabled, style, required, fill, inputProps, ...props }: Omit<ISelectProps<string>, 'itemRenderer'> & { fill?: boolean, required?: boolean, style?: React.CSSProperties }) {
  const filterFilm: ItemPredicate<string> = React.useCallback((query, department) => {
    return department.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  }, []);

  const itemRenderer: ItemRenderer<string> = React.useCallback((department: string, { modifiers, handleClick }) => {
    if (!modifiers.matchesPredicate) { return null; }
    return (
      <bp3.MenuItem
        active={modifiers.active}
        key={department}
        onClick={handleClick}
        text={department}
        shouldDismissPopover={false}
      />
    );
  }, []);

  return (
    <Select<string>
      {...props}
      inputProps={inputProps}
      items={items}
      activeItem={activeItem}
      itemPredicate={filterFilm}
      itemRenderer={itemRenderer}
      popoverProps={{ fill }}
      noResults={React.useMemo(() => <bp3.MenuItem disabled={true} text="No results." />, [])}
      disabled={disabled}
    >
      <bp3.Button
        tabIndex={0}
        rightIcon={IconNames.DOUBLE_CARET_VERTICAL}
        text={activeItem ?? ' '}
        alignText={bp3.Alignment.LEFT}
        style={{ ...style, position: 'relative' }}
        disabled={disabled}
        fill={fill}
      >
        {inputProps && <input
          type="text"
          name={inputProps.name}
          value={activeItem?.toString()}
          style={{ ...abs0000, opacity: 0, zIndex: -1 }}
          required={required}
        />}
      </bp3.Button>
    </Select>
  );
}*/

export type WithId = { id: string, name: string };

export function WithIdSelect<T extends WithId>({ items, activeItem, disabled, style, required, fill, minimal, large, inputProps, labelRenderer, ...props }:
  Omit<ISelectProps<T>, 'itemRenderer'> & { fill?: boolean, minimal?: boolean, large?: boolean, required?: boolean, style?: React.CSSProperties, labelRenderer?: (item: T) => string }) {
  const itemPredicate: ItemPredicate<T> = React.useCallback((query, item) => {
    return item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  }, []);

  const itemRenderer: ItemRenderer<T> = React.useCallback((item: T, { modifiers, handleClick }) => {
    if (!modifiers.matchesPredicate) { return null; }
    return (
      <bp3.MenuItem
        active={modifiers.active}
        key={item.id}
        onClick={handleClick}
        text={item.name}
        label={labelRenderer?.(item)}
        shouldDismissPopover={false}
      />
    );
  }, [labelRenderer]);

  const itemsEqual = React.useCallback((a: T, b: T) => a.id === b.id, []);

  let text: string | JSX.Element | ICreateNewItem = ' ';
  if (activeItem) {
    if ('name' in activeItem) {
      const label = labelRenderer?.(activeItem);
      text = <>
        {activeItem.name}
        {label && <span className={bp3.Classes.TEXT_MUTED}> — </span>}
        {label && <span className={bp3.Classes.TEXT_MUTED}>{labelRenderer?.(activeItem) ?? ''}</span>}
      </>;
    } else {
      text = activeItem;
    }
  }

  return (
    <Select<T>
      {...props}
      inputProps={inputProps}
      items={items}
      activeItem={activeItem}
      itemPredicate={itemPredicate}
      itemRenderer={itemRenderer}
      itemsEqual={itemsEqual}
      popoverProps={{ fill }}
      noResults={React.useMemo(() => <bp3.MenuItem disabled={true} text="No results." />, [])}
      disabled={disabled}
    >
      <bp3.Button
        rightIcon={IconNames.DOUBLE_CARET_VERTICAL}
        tabIndex={0}
        text={text}
        alignText={bp3.Alignment.LEFT}
        style={{ ...style, position: 'relative' }}
        disabled={disabled}
        fill={fill}
        minimal={minimal}
        large={large}
      >
        {inputProps && <input
          type="text"
          name={inputProps.name}
          value={activeItem && ('name' in activeItem) ? activeItem.name : ' '}
          style={{ ...abs0000, opacity: 0, zIndex: -1 }}
          required={required}
        />}
      </bp3.Button>
    </Select>
  );
}