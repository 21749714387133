import React, {useEffect, useState} from 'react';
import './SubmissionForm.module.css';
import * as util from "../utils";
import classNames from "classnames";
import {Itinerary} from "../components/Itinerary";
import {useMatch} from "react-router-dom";
import {api, GET} from "../api";
import ErrorState from "../components/ErrorState";
import * as bp4 from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import AddOrEditStudentDialog from "./AddOrEditStudentDialog";

export default function SubmissionForm() {
    const [darkMode] = util.useDarkMode();
    useEffect(() => { document.body.classList.toggle('dark-mode', darkMode) }, [darkMode]);

    const [loadingState, setLoadingState] = useState<'loading' | 'error' | 'loaded'>('loading');
    const [booking, setBooking] = useState<any | null>(null);
    const [error, setError] = useState<any | null>(null);
    const {id} = useMatch({path: '/bookings/:id'})?.params as any;
    const loadBooking = async () => {
        try {
            const response = await fetch(api(`/api/v1/bookings/${id}`));
            if(response.status !== 200) {
                setError(`HTTP Error ${response.status}: ${await response.text()}`);
                setLoadingState('error');
            } else {
                setBooking(await response.json());
                setLoadingState('loaded');
            }
        } catch (e) {
            setError(`Unknown Error: ${e}`);
            setLoadingState('error');
        }
    };
    useEffect(() => {
        loadBooking().then(_ => {});
    }, [id, setLoadingState, setBooking, setError]);

    const [locked, setLocked] = React.useState(false);
    useEffect(() => {
        if(booking) {
            fetch(api(`/api/v1/plans/${booking.bookingTime.yearWeek.year}/${booking.bookingTime.yearWeek.weekNumber + 1}/lock`, {
                method: GET
            })).then(response => {
                response.json().then(setLocked);
            });
        }
    }, [booking, setLocked]);
    const [canEdit, setCanEdit] = React.useState(false);
    useEffect(() => {
        setCanEdit(!locked && booking?.status === 'IN_CREATION');
    }, [setCanEdit, locked, booking]);

    const [showStudentDialog, setShowStudentDialog] = useState<boolean>(false);
    const openStudentDialog = () => setShowStudentDialog(true);
    const closeStudentDialog = async () => {
        setShowStudentDialog(false);
        await loadBooking();
    };
    const [student, setStudent] = useState<any>({isNew: true});

    const deleteStudent = async (student: any) => {
        // eslint-disable-next-line no-restricted-globals
        if(confirm(`Sind Sie sich sicher, dass Sie den Schüler ${student.name} löschen möchten?`)) {
            try {
                const response = await fetch(api(`/api/v1/students/${student.id}`, {
                    method: 'DELETE'
                }));
                if(response.status !== 200) {
                    setError(`HTTP Error ${response.status}: ${await response.text()}`);
                    setLoadingState('error');
                } else {
                    await loadBooking();
                }
            } catch (e) {
                setError(`Unknown Error: ${e}`);
                setLoadingState('error');
            }
        }
    };

    const [file, setFile] = useState<File>();

    return <div className={classNames({ App: true, 'bp4-dark': darkMode })}>
        <div>
            <ErrorState show={loadingState === 'error'} error={error} />

            {loadingState === 'loading'
                && <bp4.NonIdealState
                    title="Laden ..."
                    icon={IconNames.DATA_CONNECTION}
                    description={<>
                        <bp4.Spinner />
                    </>} />}

            {loadingState === 'loaded' &&
                <div style={{maxWidth: "820px", margin: "0 auto 20px", textAlign: "left"}}>

                    <Itinerary permissions="restricted" activeSchool={booking} />

                    <bp4.Divider />

                    {canEdit && <bp4.ButtonGroup>
                        <bp4.Button
                            icon={IconNames.ADD}
                            intent={bp4.Intent.SUCCESS}
                            text="Schüler hinzufügen"
                            onClick={() => {
                                setStudent({isNew: true});
                                openStudentDialog();
                            }} />
                        <bp4.Button
                            icon={IconNames.DOWNLOAD}
                            intent={bp4.Intent.PRIMARY}
                            text="Namenliste herunterladen"
                            onClick={async () => {
                                window.open(`${process.env.REACT_APP_API || window.location.origin}/api/v1/bookings/${booking.id}/Schuelerliste.xlsx`, '_blank');
                            }} />
                        <bp4.Button
                            icon={IconNames.DOWNLOAD}
                            intent={bp4.Intent.PRIMARY}
                            text="Liste herunterladen"
                            onClick={async () => {
                                window.open(`${process.env.REACT_APP_API || window.location.origin}/api/v1/bookings/${booking.id}/all/Schuelerliste.xlsx`, '_blank');
                            }} />
                        <bp4.FileInput
                            text={file? file.name : "Schüllerliste..."} onInputChange={(value) => {
                                if((value.currentTarget.files?.length || 0) > 0) {
                                    const files = value.currentTarget.files || [];
                                    const file = files[0];
                                    if(file) setFile(file);
                                }
                        }} />
                        <bp4.Button
                            icon={IconNames.UPLOAD}
                            intent={bp4.Intent.PRIMARY}
                            disabled={file === undefined}
                            text="Schüllerliste hochladen" onClick={async () => {
                                try {
                                    if (file) {
                                        const body = new FormData();
                                        body.append('bookingFile', file);
                                        const response = await fetch(api(`/api/v1/bookings/${booking.id}/students/excel`, {
                                            method: 'POST',
                                            body: body
                                        }));
                                        if(response.status === 400) {
                                            const {message: text} = await response.json();
                                            window.alert(text);
                                        } else if (response.status !== 200) {
                                            setError(`HTTP Error ${response.status}: ${await response.text()}`);
                                            setLoadingState('error');
                                        } else {
                                            setFile(undefined);
                                            await loadBooking();
                                        }
                                    }
                                } catch(e) {
                                    setError(`Unknown Error: ${e}`);
                                    setLoadingState('error');
                                }
                        }} />
                    </bp4.ButtonGroup>}

                    <h2>Schüllerliste:</h2>

                    <bp4.HTMLTable bordered striped style={{width: '100%'}}>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Aktivitäten</th>
                            <th>Aktionen</th>
                        </tr>
                        </thead>
                        <tbody>
                        {booking.students.sort((a: any, b: any) => a.name.localeCompare(b.name)).map((student: any) => <tr key={student.id}>
                            <td>{student.name}</td>
                            <td>{student.activities
                                .filter(({disabled} : {disabled : boolean}) => !disabled)
                                .map((activity: any) =>
                                <bp4.Tag key={activity.id} style={{marginRight: '5px'}}>{activity.name}</bp4.Tag>)}</td>
                            <td>
                                {canEdit && <bp4.ButtonGroup>
                                    <bp4.AnchorButton
                                        intent={bp4.Intent.PRIMARY}
                                        icon={IconNames.EDIT} onClick={() => {
                                            setStudent(student);
                                            openStudentDialog();
                                    }} />
                                    <bp4.AnchorButton
                                        intent={bp4.Intent.DANGER}
                                        icon={IconNames.DELETE} onClick={() => deleteStudent(student)} />
                                </bp4.ButtonGroup>}
                            </td>
                        </tr>)}
                        </tbody>
                    </bp4.HTMLTable>

                    <AddOrEditStudentDialog
                        bookingId={booking.id}
                        isOpen={showStudentDialog}
                        close={closeStudentDialog}
                        student={student}
                        yearWeek={booking.bookingTime.yearWeek} />
                </div>}
        </div>
    </div>;
}