import React, {Dispatch, SetStateAction, useState} from 'react';

import * as bp3 from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import classNames from 'classnames';
import * as rc from 'recoil';
import {Formik} from 'formik';

import { api, POST } from '../api';
import { darkModeState } from '../utils';
import {TimePrecision} from "@blueprintjs/datetime";
import {DateInput2} from "@blueprintjs/datetime2";
import moment from "moment";

export function EditItineraryDialog({ isOpen, setIsOpen, school, permissions = 'all' } : { isOpen: boolean, setIsOpen: Dispatch<SetStateAction<boolean>>, school: any, permissions: 'all' | 'restricted' }) {
    const [darkMode] = rc.useRecoilState(darkModeState);

    const abort = React.useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const [globalError, setGlobalError] = useState<string>("");
    const saveSchool = async (updatedSchool : any) => {
        try {
            const { id } = school;
            const response = await fetch(api(`/api/v1/bookings/${id}`, {method: POST, body: updatedSchool}));
            if (response.ok) {
                setGlobalError("");
                setIsOpen(false);
                window.location.reload();
            } else {
                setGlobalError('error');
            }
        } catch {
            setGlobalError('error');
        }
    };

    return(
        <bp3.Dialog
            className={classNames({ 'bp4-dark': darkMode })}
            icon={IconNames.EDIT}
            title={`Bearbeiten von ${school.schoolInfo.schoolName}`}
            onClose={abort}
            isOpen={isOpen}
            canOutsideClickClose={false}
        >
            <>
                <div>
                    <Formik initialValues={school} onSubmit={saveSchool} enableReinitialize={true}>
                        {({
                            values,
                            setFieldValue,
                            handleSubmit
                          }) => (<>
                            {globalError !== "" && <>
                                <bp3.NonIdealState
                                    icon={IconNames.ERROR}
                                    description={<>
                                        <div>Basisdaten konnten nicht gespeichert werden. Bitte versuchen Sie die Seite neu zu laden:</div>
                                        <bp3.Button icon={IconNames.REPEAT} style={{ marginTop: 15 }} onClick={() => window.location.reload()}>Seite neu laden</bp3.Button>
                                    </>}
                                />
                            </>}
                            {globalError === "" && <form onSubmit={handleSubmit}>
                                <div className={bp3.Classes.DIALOG_BODY} style={{minHeight: 400}}>
                                    <bp3.FormGroup label={'Schulname:'} className={bp3.Classes.TEXT_MUTED}>
                                        <bp3.InputGroup
                                            value={values.schoolInfo.schoolName ?? ''}
                                            onChange={(event: any) => setFieldValue('schoolInfo.schoolName', event.currentTarget.value)}/>
                                    </bp3.FormGroup>
                                    <bp3.FormGroup label={'Schulleiter:'} className={bp3.Classes.TEXT_MUTED}>
                                        <bp3.InputGroup
                                            value={values.schoolInfo.contactName ?? ''}
                                            onChange={(event: any) => setFieldValue('schoolInfo.contactName', event.currentTarget.value)}/>
                                    </bp3.FormGroup>
                                    <bp3.FormGroup label={'Telefon:'} className={bp3.Classes.TEXT_MUTED}>
                                        <bp3.InputGroup
                                            value={values.schoolInfo.phone ?? ''}
                                            onChange={(event: any) => setFieldValue('schoolInfo.phone', event.currentTarget.value)}/>
                                    </bp3.FormGroup>
                                    <bp3.FormGroup label={'Email:'} className={bp3.Classes.TEXT_MUTED}>
                                        <bp3.InputGroup
                                            value={values.schoolInfo.email ?? ''}
                                            onChange={(event: any) => setFieldValue('schoolInfo.email', event.currentTarget.value)}/>
                                    </bp3.FormGroup>
                                    <bp3.FormGroup label={'Lehrer:'} className={bp3.Classes.TEXT_MUTED}>
                                        <bp3.NumericInput
                                            value={values.teachersCount}
                                            onValueChange={(value: number) => setFieldValue('teachersCount', value)}/>
                                    </bp3.FormGroup>
                                    <bp3.FormGroup label={'Ankunft:'} className={bp3.Classes.TEXT_MUTED}>
                                        <DateInput2
                                            value={values.bookingTime.from}
                                            onChange={date => setFieldValue('bookingTime.from', moment(date).format('YYYY-MM-DDTHH:mm'))}
                                            placeholder={'TT.MM.JJJJ HH:mm'}
                                            maxDate={moment('31.12.2099', 'DD.MM.YYYY').toDate()}
                                            timePrecision={TimePrecision.MINUTE}
                                            parseDate={ (text) => moment(text, 'DD.MM.YYYY HH:mm').toDate()}
                                            formatDate={(date) => moment(date).format('DD.MM.YYYY HH:mm')}
                                            popoverProps={{usePortal: false, placement: 'top'}} />
                                    </bp3.FormGroup>
                                    <bp3.FormGroup label={'Sport Start:'} className={bp3.Classes.TEXT_MUTED}>
                                        <DateInput2
                                            value={values.bookingTime.sportStart}
                                            onChange={date => setFieldValue('bookingTime.sportStart', moment(date).format('YYYY-MM-DDTHH:mm'))}
                                            placeholder={'TT.MM.JJJJ HH:mm'}
                                            maxDate={moment('31.12.2099', 'DD.MM.YYYY').toDate()}
                                            timePrecision={TimePrecision.MINUTE}
                                            parseDate={ (text) => moment(text, 'DD.MM.YYYY HH:mm').toDate()}
                                            formatDate={(date) => moment(date).format('DD.MM.YYYY HH:mm')}
                                            popoverProps={{usePortal: false, placement: 'top'}} />
                                    </bp3.FormGroup>
                                    <bp3.FormGroup label={'Sport Ende:'} className={bp3.Classes.TEXT_MUTED}>
                                        <DateInput2
                                            value={values.bookingTime.sportEnd}
                                            onChange={date => setFieldValue('bookingTime.sportEnd', moment(date).format('YYYY-MM-DDTHH:mm'))}
                                            placeholder={'TT.MM.JJJJ HH:mm'}
                                            maxDate={moment('31.12.2099', 'DD.MM.YYYY').toDate()}
                                            timePrecision={TimePrecision.MINUTE}
                                            parseDate={ (text) => moment(text, 'DD.MM.YYYY HH:mm').toDate()}
                                            formatDate={(date) => moment(date).format('DD.MM.YYYY HH:mm')}
                                            popoverProps={{usePortal: false, placement: 'top'}} />
                                    </bp3.FormGroup>
                                    <bp3.FormGroup label={'Abfahrt:'} className={bp3.Classes.TEXT_MUTED}>
                                        <DateInput2
                                            value={values.bookingTime.to}
                                            onChange={date =>  setFieldValue('bookingTime.to', moment(date).format('YYYY-MM-DDTHH:mm'))}
                                            placeholder={'TT.MM.JJJJ HH:mm'}
                                            maxDate={moment('31.12.2099', 'DD.MM.YYYY').toDate()}
                                            timePrecision={TimePrecision.MINUTE}
                                            parseDate={ (text) => moment(text, 'DD.MM.YYYY HH:mm').toDate()}
                                            formatDate={(date) => moment(date).format('DD.MM.YYYY HH:mm')}
                                            popoverProps={{usePortal: false, placement: 'top'}} />
                                    </bp3.FormGroup>
                                    <bp3.FormGroup label={'Mittagspause:'} className={bp3.Classes.TEXT_MUTED}>
                                        <bp3.InputGroup
                                            value={values.lunchBreak}
                                            onChange={(event: any) => setFieldValue('lunchBreak', event.currentTarget.value)}/>
                                    </bp3.FormGroup>
                                    <bp3.FormGroup label={'Sporteinheiten:'} className={bp3.Classes.TEXT_MUTED}>
                                        <bp3.NumericInput
                                            value={values.sportUnits}
                                            min={-1}
                                            max={100}
                                            stepSize={1}
                                            onValueChange={(value: number) => setFieldValue('sportUnits', value)}/>
                                    </bp3.FormGroup>
                                    <bp3.FormGroup label={'Tageszeit:'} className={bp3.Classes.TEXT_MUTED}>
                                        <bp3.HTMLSelect value={values.allowedDayTimes}
                                                        onChange={(value: any) => setFieldValue('allowedDayTimes', value.target.value)}>
                                            <option value={'All'}>Ganzt&auml;gig</option>
                                            <option value={'Morning'}>Vormittags</option>
                                            <option value={'Afternoon'}>Nachmittags</option>
                                        </bp3.HTMLSelect>
                                    </bp3.FormGroup>
                                </div>
                                <div className={bp3.Classes.DIALOG_FOOTER}>
                                    <div className={bp3.Classes.DIALOG_FOOTER_ACTIONS}>
                                        <bp3.Button onClick={abort}>Abbrechen</bp3.Button>
                                        <bp3.Button intent={bp3.Intent.PRIMARY} type="submit">Speichern</bp3.Button>
                                    </div>
                                </div>
                            </form>}
                        </>)}
                    </Formik>
                </div>
            </>
        </bp3.Dialog>
    );
}