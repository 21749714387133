import 'broadcastchannel-polyfill';

import React, {useEffect, useState} from 'react';

import * as bp3 from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import classNames from 'classnames';
import * as rc from 'recoil';
import * as df from 'date-fns';
import { useMatch, useNavigate, Link } from 'react-router-dom';
import { DateInput } from '@blueprintjs/datetime';

import * as util from './utils';
import * as state from './state';

import { TitleBar } from './components/TitleBar';
import { Slot } from './components/Slot';
import { Itinerary } from './components/Itinerary';
import { formattedActivityName, formattedActivityNameWithTimeString } from './components/common';

import './App.css';
import {api, DELETE, GET, POST} from "./api";
import ActivitySettingsDialog from "./components/ActivitySettingsDialog";

function App() {
  const [darkMode] = util.useDarkMode();
  React.useEffect(() => { document.body.classList.toggle('dark-mode', darkMode) }, [darkMode]);

  const [isOpen, setIsOpen] = React.useState(false);
  const [isShareOpen, setIsShareOpen] = React.useState(false);
  const [expandAll, setExpandAll] = React.useState(false);

  const setTime = rc.useSetRecoilState(state.time);
  const date = util.useRecoilValueLoadable(state.date).contents;

  const history = useNavigate();
  const matchWithId = useMatch({path: '/:yyyy/:ww/:sort/:filter/:id'});
  const matchWithoutId = useMatch({path: '/:yyyy/:ww/:sort/:filter'});
  const { yyyy, ww, sort = 'Sportart', filter = 'Alle', id = null } = (matchWithId || matchWithoutId)?.params as unknown as { yyyy: string, ww: string, sort: string, filter: string, id: string } ?? {};
  const [year, week] = [yyyy, ww].map(Number);

  const openSlot = util.elongateId(id);
  const bySchool = sort === 'Schule';
  const sportFilter = !bySchool && filter !== 'Alle' ? filter : null;
  const schoolFilter = bySchool && filter !== 'Alle' ? util.elongateId(filter) : null;

  const { state: activitiesState, contents: activitiesForFilter } = util.useRecoilValueLoadable(state.activitiesForFilter);
  const { contents: activities } = util.useRecoilValueLoadable(state.activities);
  const [currentCapacity, setCurrentCapactiy] = React.useState(activities?.find(activity => activity.name === sportFilter)?.capacity || 0);
  const [currentMaxUnits, setCurrentMaxUnits] = React.useState(activities?.find(activity => activity.name === sportFilter)?.maxUnits || 0);

  const mapCapacitySettingToDayTime = (activity: any) => {
    if(!activity || (activity?.morning && activity?.afternoon)) {
      return 'all';
    }
    if(activity.morning) {
      return 'morning';
    }
    return 'afternoon';
  }
  const [currentDayTime, setCurrentDayTime] = React.useState(mapCapacitySettingToDayTime(activities?.find(activity => activity.name === sportFilter)) || 0);
  useEffect(() => {
    setCurrentDayTime(mapCapacitySettingToDayTime(activities?.find(activity => activity.name === sportFilter)) || 0);
    setCurrentCapactiy(activities?.find(activity => activity.name === sportFilter)?.capacity || 0);
    setCurrentMaxUnits(activities?.find(activity => activity.name === sportFilter)?.maxUnits || 0);
  }, [sportFilter, activities, setCurrentCapactiy, setCurrentMaxUnits, setCurrentDayTime]);
  const { state: schoolsState, contents: schools } = util.useRecoilValueLoadable(state.schools);
  const { state: slotsState, contents: slotsWithActivity } = util.useRecoilValueLoadable(state.filteredSlotsByActivity(sportFilter));
  const { state: slotsWithSchoolState, contents: slotsWithSchool } = util.useRecoilValueLoadable(state.filteredSlotsBySchool(schoolFilter));
  const { state: nrParticipantsState, contents: nrParticipants } = util.useRecoilValueLoadable(state.totalWeeklyParticipants);

  const activeSchool = slotsWithSchool?.school;
  const slotsBySchool = slotsWithSchool?.slots;

  const hasError = [activitiesState, schoolsState, slotsState, slotsWithSchoolState, nrParticipantsState].some(x => x === 'hasError');
  const showNonIdealState = hasError || nrParticipants === 0;

  const reload = rc.useRecoilCallback(({ set }) => () => {
    set(state.requestId([year, week]), id => ++id)
    return util.noop;
  }, [year, week]);

  // Add descriptive document title
  const slot = util.useRecoilValueLoadable(state.slotById(openSlot ?? '')).contents;
  const school = util.useRecoilValueLoadable(state.schoolById(schoolFilter ?? '')).contents;
  React.useEffect(() => {
    document.title = [
      'Hotel Royal X',
      year,
      `W${week}`,
      bySchool
        ? school?.schoolInfo?.schoolName
        : !slot && filter,
      formattedActivityNameWithTimeString(slot)
    ]
      .filter(x => !!x && x !== 'Alle')
      .reverse()
      .join(' | ');
  }, [year, week, sort, filter, slot, school, bySchool]);

  // Keep multiple tabs in sync.
  const onMessage = rc.useRecoilCallback(({ set }) => (e: MessageEvent<any>) => {
    const { year, week } = e.data;
    set(state.requestId([year, week]), id => ++id)
    return util.noop;
  }, []);

  React.useEffect(() => {
    const c = new BroadcastChannel('activity-planner');
    c.addEventListener('message', onMessage);
    return () => c.removeEventListener('message', onMessage);
  }, [onMessage])


  // Set to current week when opening root
  React.useEffect(() => {
    let dt: Date;
    if (!year || !week) {
      dt = df.startOfISOWeek(Date.now());
      history(`/${df.getISOWeekYear(dt)}/${df.getISOWeek(dt)}/Sportart/Alle`, { replace: true });
    } else {
      dt = util.startOfISOWeekFromYearWeek(year, week);
    }

    setTime(dt.getTime());
  }, [history, setTime, year, week]);

  // Week forward/backward links
  const prevWeekTo = week - 1 === 0
    ? `/${year - 1}/${df.getISOWeeksInYear(new Date(year - 1, 0))}/${sort}/${sportFilter ?? ''}`
    : `/${year}/${week - 1}/${sort}/${sportFilter ?? ''}`;

  const nextWeekTo = week + 1 > df.getISOWeeksInYear(new Date(year, 0))
    ? `/${year + 1}/1/${sort}/${sportFilter ?? ''}`
    : `/${year}/${week + 1}/${sort}/${sportFilter ?? ''}`;

  const setWeek = React.useCallback((date) => {
    if (date != null) {
      window.location.href = (`${process.env.REACT_APP_API || window.location.origin}/#/${df.getISOWeekYear(date)}/${df.getISOWeek(date)}/${sort}/${sportFilter ?? 'Alle'}`);
      window.location.reload();
    }
  }, [history, sort, sportFilter]);

  const addSlot = React.useCallback(async (activity) => {
    const response = await fetch(api(`/api/v1/slots/${year}/${week}`, { method: POST, body: { activity, start: '08:00', end: '09:30' } }));
    if(response.ok) {
      reload();
    } else {
      alert('Es ist leider ein Fehler aufgetreten!');
    }
  }, [year, week, reload]);

  const resetPlan = React.useCallback(async () => {
    if(!window.confirm('Achtung! Wollen Sie wirklich den Plan neu genereiren? Hierbei werden alle Änderungen gelöscht werden.')) {
      return;
    }
    const response = await fetch(api(`/api/v1/plans/${year}/${week}`, { method: DELETE }));
    if(response.ok) {
      reload();
    } else {
      alert('Es ist leider ein Fehler aufgetreten!');
    }
  }, [year, week, reload]);

  const [locked, setLocked] = React.useState(false);

  useEffect(() => {
    fetch(api(`/api/v1/plans/${year}/${week}/lock`, {
      method: GET
    })).then(response => {
      response.json().then(setLocked);
    });
  }, [setLocked, year, week])

  const lockPlan = React.useCallback(async () => {
    const response = await fetch(api(`/api/v1/plans/${year}/${week}/lock`, {
      method: POST
    }));
    if(response.ok) {
      setLocked(true);
    } else {
      alert('Es ist ein Fehler beim sperren des Plans aufgetreten!');
    }
  }, [year, week, setLocked]);
  const unlockPlan = React.useCallback(async () => {
    const response = await fetch(api(`/api/v1/plans/${year}/${week}/lock`, {
      method: DELETE
    }));
    if(response.ok) {
      setLocked(false);
    } else {
      alert('Es ist ein Fehler beim freigeben des Plans aufgetreten!');
    }
  }, [year, week, setLocked]);

  const changeCapacity = React.useCallback(async (capacity: number) => {
    if(capacity === currentCapacity) {
      return;
    }
    const activity = activities?.find(activity => activity.name === sportFilter);
    if(activity) {
      const response = await fetch(api(`/api/v1/activities/${activity.id}/year/${year}/week/${week}`, {
        method: POST,
        body: {
          'capacity': capacity,
          'morning': currentDayTime === 'all' || currentDayTime === 'morning',
          'afternoon': currentDayTime === 'all' || currentDayTime === 'afternoon',
          'maxUnits': currentMaxUnits
        }
      }));
      if (response.ok) {
        setCurrentCapactiy(capacity);
      }
    }
  }, [year, week, sportFilter, activities, currentCapacity, currentDayTime]);

  const changeMaxUnits = React.useCallback(async (number) => {
    if(number === currentMaxUnits)
      return;
    const activity = activities?.find(activity => activity.name === sportFilter);
    if(activity) {
      const response = await fetch(api(`/api/v1/activities/${activity.id}/year/${year}/week/${week}`, {
        method: POST,
        body: {
          'capacity': currentCapacity,
          'morning': currentDayTime === 'all' || currentDayTime === 'morning',
          'afternoon': currentDayTime === 'all' || currentDayTime === 'afternoon',
          'maxUnits': number
        }
      }));
      if (response.ok) {
        setCurrentMaxUnits(number);
      }
    }
  }, [year, week, sportFilter, activities, currentCapacity, currentDayTime]);

  const changeActivityDayTime = React.useCallback(async (event: any) => {
    const dayTime = event.target.value;
    if(dayTime === currentDayTime) {
      return;
    }
    const activity = activities?.find(activity => activity.name === sportFilter);
    if(activity) {
      const response = await fetch(api(`/api/v1/activities/${activity.id}/year/${year}/week/${week}`, {
        method: POST,
        body: {
          'capacity': currentCapacity || activities?.find(activity => activity.name === sportFilter)?.capacity,
          'morning': dayTime === 'all' || dayTime === 'morning',
          'afternoon': dayTime === 'all' || dayTime === 'afternoon'
        }
      }));
      if (response.ok) {
        //reload();
        console.log('Ok!');
      } else {
        //alert('Es ist leider ein Fehler aufgetreten!');
      }
    }
    setCurrentDayTime(dayTime);
  }, [currentDayTime, setCurrentDayTime, currentCapacity, activities, sportFilter, week, year]);

  // Hacky restore scroll position
  React.useLayoutEffect(() => {
    if (slotsState === 'hasValue') {
      window.scrollTo(0, (window as any).__scrollPos ?? 0);
      delete (window as any).__scrollPos;
    }
  }, [slotsState]);

  const [showActivitySettingsDialog, setShowActivitySettingsDialog] = useState<boolean>(false);

  // @ts-ignore
  return <div className={classNames({ App: true, 'bp4-dark': darkMode })}>
    <header>
      <TitleBar isOpen={isOpen} setIsOpen={setIsOpen} isShareOpen={isShareOpen} setIsShareOpen={setIsShareOpen} />
      <bp3.Navbar style={{ height: 40 }}>
        <bp3.NavbarGroup className="space-between" style={{ height: 40, width: '100%' }}>
          <div className="align-items-center" style={{ flex: 1 }}>
            <span>Sortieren nach</span>
            <bp3.HTMLSelect
              style={{ marginLeft: 5 }}
              options={['Sportart', 'Schule']}
              value={sort}
              onChange={(e) => {
                const sort = e.target.value;
                if (sort === 'Schule') {
                  history(`/${year}/${week}/Schule/${util.shortenId(schools?.[0]?.id) ?? ''}`)
                } else if (sort === 'Sportart') {
                  history(`/${year}/${week}/Sportart/Alle`)
                }
              }}
            />
            <bp3.Button
              style={{ marginLeft: 10 }}
              icon={expandAll ? IconNames.COLLAPSE_ALL : IconNames.EXPAND_ALL}
              title={expandAll ? 'Alle zuklappen' : 'Alle ausklappen'}
              active={expandAll}
              onClick={() => setExpandAll(!expandAll)}
            />
          </div>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <bp3.ControlGroup vertical={false} style={{ margin: 'auto' }}>
              <bp3.AnchorButton icon={IconNames.CHEVRON_LEFT} onClick={()  => {
                const newWeek = week === 1? 52 : week - 1;
                window.location.href = (`${process.env.REACT_APP_API || window.location.origin}/#/${year}/${newWeek}/${sort}/${sportFilter ?? 'Alle'}`);
                window.location.reload();
              }} />
              <DateInput
                formatDate={d => util.weeklyString({ from: df.startOfISOWeek(d), to: df.endOfISOWeek(d) })}
                parseDate={() => date}
                inputProps={{ readOnly: true, style: { fontWeight: 'bold', minWidth: 230, textAlign: 'center' } }}
                popoverProps={{ boundary: 'viewport' }}
                value={date}
                onChange={setWeek}
                minDate={new Date(2019, 0)}
                maxDate={df.endOfISOWeekYear(df.addYears(Date.now(), 5))}
                dayPickerProps={{ locale: 'de', firstDayOfWeek: 1, fixedWeeks: true, showWeekNumbers: true, renderWeek: (_, [d]) => `W${df.getISOWeek(d)}` }}
              />
              <bp3.AnchorButton icon={IconNames.CHEVRON_RIGHT} onClick={() => {
                const newWeek = week === 52? 1 : week + 1;
                window.location.href = (`${process.env.REACT_APP_API || window.location.origin}/#/${year}/${newWeek}/${sort}/${sportFilter ?? 'Alle'}`);
                window.location.reload();
              }} />
              {/* <bp3.Button icon={IconNames.REFRESH} onClick={state.useRefreshYearWeek([year, week])} /> */}
            </bp3.ControlGroup>
          </div>
          {/* <div style={{ visibility: 'hidden' }}>Sortieren nach <bp3.HTMLSelect minimal options={['Sportart', 'Schule']} value={sort} onChange={() => { }}></bp3.HTMLSelect></div> */}
          <div style={{ flex: 1, textAlign: 'right' }}>
            W{date && df.getISOWeek(date)}
            {' | '}
            {util.useRecoilValueLoadable(state.totalWeeklyParticipants).contents ?? '?'}
            {' Schüler'}

            {!locked && <bp3.Button
                onClick={resetPlan}
                style={{'margin': 10}}
                icon={IconNames.REFRESH}
                intent={bp3.Intent.WARNING} />}
            {!locked && <bp3.Button
                onClick={lockPlan}
                style={{'margin': 10}}
                icon={IconNames.UNLOCK}
                intent={bp3.Intent.SUCCESS} />}
            {locked && <bp3.Button
                onClick={unlockPlan}
                style={{'margin': 10}}
                icon={IconNames.LOCK}
                intent={bp3.Intent.DANGER} />}
          </div>
        </bp3.NavbarGroup>
      </bp3.Navbar>
    </header>
    <main>
      {showNonIdealState && <section style={{ marginTop: 25 }}>
          {hasError
            ? <bp3.NonIdealState
                icon={IconNames.ERROR}
                title="Hier ist etwas ist schief gelaufen!"
                description={<>
                  <div>Bitte versuchen Sie es erneut:</div>
                  <bp3.Button icon={IconNames.REPEAT} style={{ marginTop: 15 }} onClick={reload}>Neu laden</bp3.Button>
                </>}
              />
            : <bp3.NonIdealState
                icon={IconNames.INBOX}
                title="Keine Buchungen vorhanden"
                description={<>
                  <div>Für den Zeitraum von <strong>{util.weeklyString({ from: df.startOfISOWeek(date ?? 0), to: df.endOfISOWeek(date ?? 0) })}</strong> sind noch keine Buchnungen vorhanden!</div>
                  <bp3.Button icon={IconNames.UPLOAD} style={{ marginTop: 15 }} onClick={() => setIsOpen(true)}>Neue Buchung</bp3.Button>
                </>}
              />}
        </section>}
      {<bp3.ButtonGroup style={{ margin: '20px auto' }}>
          {bySchool
            ? <>
              {schoolsState === 'loading'
                ? <bp3.Spinner size={bp3.SpinnerSize.SMALL} />
                : schools?.map(s => (
                  <Link
                    key={s.id}
                    to={`/${year}/${week}/${sort}/${util.shortenId(s.id)}`}
                    // @ts-ignore
                    active={s.id === schoolFilter}
                  >{s.schoolInfo.schoolName}</Link>
                ))}
            </> : <>
              {activitiesState === 'loading'
                ? <bp3.Spinner size={bp3.SpinnerSize.SMALL} />
                : <>
                  <Link to={`/${year}/${week}/${sort}/Alle`}>
                   <bp3.AnchorButton active={sportFilter === null}
                                     intent={sportFilter !== null ? bp3.Intent.WARNING : bp3.Intent.NONE}
                                     icon={sportFilter !== null ? IconNames.CLEAN : IconNames.CALENDAR}>
                     Alle Sportarten
                   </bp3.AnchorButton>
                  </Link>
                  {activitiesForFilter?.map(a => (
                    <Link
                      key={a}
                      to={`/${year}/${week}/${sort}/${a}`}
                    ><bp3.AnchorButton active={a === sportFilter}>{a}</bp3.AnchorButton></Link>
                  ))}
                </>
              }
            </>}
        </bp3.ButtonGroup>}
      {!bySchool && <>
        {sportFilter && <>
          <h3 style={{textAlign: 'center'}}>
            <bp3.AnchorButton icon="settings" onClick={() => setShowActivitySettingsDialog(true)} />{' '}
            {sportFilter !== 'Alle' && activities?.find(activity => activity.name === sportFilter)?.name}
          </h3>
          <ActivitySettingsDialog
              activity={activities?.find(activity => activity.name === sportFilter)}
              yearWeek={{year, week}}
              onClose={() => {
                setShowActivitySettingsDialog(false);
                window.location.reload();
              }}
              isOpen={showActivitySettingsDialog} />
          {/*<bp3.FormGroup label={'Tageszeit:'} className={bp3.Classes.TEXT_MUTED} >
            <bp3.RadioGroup onChange={changeActivityDayTime} inline={true} selectedValue={currentDayTime || mapCapacitySettingToDayTime(activities?.find(activity => activity.name === sportFilter))}>
              <bp3.Radio label={'Ganz'} value={'all'} />
              <bp3.Radio label={'Vormittags'} value={'morning'} />
              <bp3.Radio label={'Nachmittags'} value={'afternoon'} />
            </bp3.RadioGroup>
          </bp3.FormGroup>
          <bp3.FormGroup label={'Kapazität:'} className={bp3.Classes.TEXT_MUTED} >
            <bp3.Slider
                onChange={changeCapacity}
                min={1}
                max={5}
                stepSize={1}
                value={currentCapacity || activities?.find(activity => activity.name === sportFilter)?.capacity}
            />
          </bp3.FormGroup>
          <bp3.FormGroup label={'Maximale Einheiten:'} className={bp3.Classes.TEXT_MUTED} >
            <bp3.Slider
                onChange={changeMaxUnits}
                min={1}
                max={60}
                labelStepSize={10}
                stepSize={1}
                value={currentMaxUnits || activities?.find(activity => activity.name === sportFilter)?.maxUnits}
            />
          </bp3.FormGroup>*/}
          <bp3.Button
              onClick={() => addSlot(activities?.find(activity => activity.name === sportFilter))}
              intent={bp3.Intent.PRIMARY}
              icon={IconNames.ADD}
              minimal>

          </bp3.Button>
        </>}
        {slotsState === 'loading'
          ? <bp3.Spinner size={bp3.SpinnerSize.LARGE} />
          : [...(slotsWithActivity as any[])]?.sort((section1, section2) => {
              const name1 = section1['activity']['name'];
              const name2 = section2['activity']['name'];
              return name1.localeCompare(name2);
            })?.map(({ activity, slots }, i, arr) => <section key={activity?.id} style={{ textAlign: 'left' }}>
              <bp3.H3>
                <bp3.Button
                    onClick={() => addSlot(activity)}
                    intent={bp3.Intent.PRIMARY}
                    icon={IconNames.ADD}
                    minimal>

                </bp3.Button>
                {' '}
                {formattedActivityName(activity)}
                <span className={bp3.Classes.TEXT_MUTED} style={{ display: 'inline-block', fontWeight: 'normal', fontSize: '14px', float: 'right' }}>
                  {slots.reduce((a: any, s: any) => a + s.participants.length, 0)} Schüler
                </span>
              </bp3.H3>
              <div className="App-bar" style={i + 1 === arr.length ? { marginBottom: 0 } : {}}>
                {slots.map((slot: any) => (
                  <Slot
                    key={slot.id}
                    slot={slot}
                    isOpen={openSlot === slot.id}
                    href={`/${year}/${week}/${sort}/${filter}/${util.shortenId(slot.id)}`}
                    expandAll={expandAll}
                  />
                ))}
              </div>
            </section>)}
      </>}
      {!showNonIdealState && bySchool && <>
        {slotsWithSchoolState === 'loading'
          ? <bp3.Spinner size={bp3.SpinnerSize.LARGE} />
          : <section>
            <Itinerary permissions="all" activeSchool={activeSchool} />
            {slotsBySchool?.length ?
              <section>
                <bp3.H3>Sportplan</bp3.H3>
                <div className="App-bar" style={{ marginBottom: 0 }}>
                  {[...slotsBySchool].sort(({activity: {name: a}}, {activity: {name: b}}) => a.localeCompare(b))?.map(slot => <Slot
                      key={slot.id}
                      slot={slot}
                      isOpen={openSlot === slot.id}
                      href={`/${year}/${week}/${sort}/${filter}/${util.shortenId(slot.id)}`}
                      activeSchool={activeSchool}
                      expandAll={expandAll}
                    />)}
                </div>
              </section> : null}
          </section>}
      </>}
    </main>
  </div>;
}

export default App;
