import React, {useState, useEffect} from 'react';
import App from "./App";
import * as bp4 from '@blueprintjs/core';

export default function AppWrapper() {
    const [credentials, setCredentials] = useState<{ username: string, password: string } | null>(null);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const storedCredentials = localStorage.getItem('credentials');
        if (storedCredentials) {
            const {username, password} = JSON.parse(storedCredentials);
            tryLogin(username, password);
        }
    }, []);

    const tryLogin = async (username: string, password: string) => {
        try {
            let headers = new Headers();
            headers.set('Authorization', 'Basic ' + btoa(username + ":" + password));
            const response = await fetch(
                `${process.env.REACT_APP_API || window.location.origin}/api/v1/warnings/2023/21`,
                {method: 'GET', headers: headers});

            if (response.status !== 200) {
                setError('Die Anmeldedaten sind ungültig!');
                localStorage.removeItem('credentials'); // If the credentials are invalid, remove them from storage
            } else {
                setCredentials({username, password});
                localStorage.setItem('credentials', JSON.stringify({username, password}));
            }
        } catch (e) {
            setError('Es gab einen Fehler bei der Anmeldung!');
            localStorage.removeItem('credentials'); // If there's an error, remove the credentials from storage
        }
    };

    return <>
        {credentials !== null && <App/>}
        {credentials === null && <div>
            <bp4.Card elevation={bp4.Elevation.ONE}>
                <div style={{display: 'flex', flexDirection: 'column', rowGap: '5px'}}>
                    {error != null && <bp4.NonIdealState icon="error" title="Fehler!">
                        <p>{error}</p>
                    </bp4.NonIdealState>}
                    <bp4.FormGroup label="Benutzername">
                        <bp4.InputGroup value={username} onChange={e => setUsername(e.target.value)}/>
                    </bp4.FormGroup>
                    <bp4.FormGroup label="Password">
                        <bp4.InputGroup type="password" value={password} onChange={e => setPassword(e.target.value)}/>
                    </bp4.FormGroup>
                    <bp4.Button intent={bp4.Intent.PRIMARY} onClick={() => tryLogin(username, password)}>
                        Anmelden
                    </bp4.Button>
                </div>
            </bp4.Card>
        </div>}
    </>;
}