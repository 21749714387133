import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { RecoilRoot } from 'recoil';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import SubmissionForm from "./submission/SubmissionForm";
import AppWrapper from "./AppWrapper";

ReactDOM.render(
  <RecoilRoot>
    <Router>
        <Routes>
            <Route path="/bookings/:id" element={<SubmissionForm />} />
            <Route path="*" element={<AppWrapper />} />
        </Routes>

    </Router>
  </RecoilRoot>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
