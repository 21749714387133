import React, {useEffect, useState} from 'react';
import * as bp4 from '@blueprintjs/core';
import {IconNames} from "@blueprintjs/icons";
import ErrorState from "./ErrorState";
import {api} from "../api";
import NewBookingDialog from "./NewBookingDialog";
import _ from 'lodash';

export function BookingsDialog({
                                   isOpen = false,
                                   close = () => {
                                   },
                                   yearWeek: {year = '0', week = '1'}
                               }: {
    isOpen: boolean,
    close: () => void,
    yearWeek: {
        year: string,
        week: string
    }
}) {

    const [loadingState, setLoadingState] = useState<'loading' | 'loaded' | 'error'>('loading');
    const [bookings, setBookings] = useState<any[]>([]);
    const [error, setError] = useState<string | null>(null);
    const loadBookings = async () => {
        try {
            const response = await fetch(api(`/api/v1/bookings/${year}/${week}`));
            if (response.status !== 200) {
                setLoadingState('error');
                setError(`HTTP Error ${response.status}: ${await response.text()}`);
            } else {
                setBookings(await response.json());
                setLoadingState('loaded');
            }
        } catch (e) {
            setLoadingState('error');
            setError(`Unknown Error: ${e}`);
        }
    };
    useEffect(() => {
        loadBookings().then(r => {
        });
    }, [setBookings, setLoadingState, setError]);

    const confirmBooking = async (id: any) => {
        try {
            const response = await fetch(api(`/api/v1/bookings/confirmation`, {
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json'
                },
                body: JSON.stringify([id])
            }));
            if (response.status !== 200) {
                setLoadingState('error');
                setError(`HTTP Error ${response.status}: ${await response.text()}`);
            } else {
                await loadBookings();
            }
        } catch (e) {
            setLoadingState('error');
            setError(`Unknown Error: ${e}`);
        }
    };
    const deleteBooking = async (id: any, name: any) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm(`Bitte bestätigen Sie, dass Sie die Schulbuchung für ${name} löschen möchten.`)) {
            try {
                const response = await fetch(api(`/api/v1/bookings`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify([id])
                }));
                if (response.status !== 200) {
                    setLoadingState('error');
                    setError(`HTTP Error ${response.status}: ${await response.text()}`);
                } else {
                    loadBookings().then(r => {
                    });
                }
            } catch (e) {
                setLoadingState('error');
                setError(`Unknown Error: ${e}`);
            }
        }
    };
    const openSchoolView = (id: any) =>
        window.open(`${process.env.REACT_APP_API || window.location.origin}/#/bookings/${id}`, '_blank');
    const [showNewBookingDialogState, setShowNewBookingDialogState] = useState<boolean>(false);
    const showNewBookingDialog = () => setShowNewBookingDialogState(true);
    const closeNewBookingDialog = async () => {
        setShowNewBookingDialogState(false);
        await loadBookings();
    };
    const [showTable, setShowTable] = useState<any | null>(null);

    return <bp4.Dialog isOpen={isOpen}
                       onClose={close}
                       icon={IconNames.CALENDAR}
                       title={`Buchungen in ${year}/${week}`}
                       style={{width: 'fit-content'}}>
        <div className={bp4.Classes.DIALOG_BODY}>
            <NewBookingDialog
                isOpen={showNewBookingDialogState}
                close={closeNewBookingDialog}
                yearWeek={{year, week}}/>
            {(loadingState === 'loaded' && bookings.length === 0)
                && <bp4.NonIdealState
                    icon={IconNames.INBOX}
                    title="Keine Buchungen vorhanden"
                    description={`Für die Kalenderwoche ${year}/${week} sind noch keine Buchungen vorhanden.`}/>}
            <ErrorState show={loadingState === 'error'} error={error}/>
            {loadingState === 'loading'
                && <bp4.NonIdealState
                    title="Laden ..."
                    icon={IconNames.DATA_CONNECTION}
                    description={<>
                        <bp4.Spinner/>
                        Buchungen für {year}/{week} werden geladen.
                    </>}/>}
            {(loadingState === 'loaded' && bookings.length > 0)
                && <bp4.HTMLTable>
                    <thead>
                    <tr>
                        <th>Schule</th>
                        <th>Status</th>
                        <th>Kontakt</th>
                        <th># Schüler</th>
                        <th>Aktionen</th>
                    </tr>
                    </thead>
                    <tbody>
                    {bookings.map(({
                                       id,
                                       status,
                                       schoolInfo: {schoolName: name, contactName, email, phone},
                                       students
                                   }) => {
                        // @ts-ignore
                        const activities = _.uniqBy(students.map(({activities}) => activities)
                            .flat()
                            // @ts-ignore
                            .map(({id, name, level}) => {
                                return {id, name, level};
                            }), ({id}) => id)
                            // @ts-ignore
                            .sort(({name: a}, {name: b}) => a.toLowerCase() - b.toLowerCase());
                        return <>
                            <tr key={`${id}-base`}>
                                <td>{name}</td>
                                <td>
                                    {status === 'IN_CREATION' && <bp4.Tag intent={bp4.Intent.WARNING}>offen</bp4.Tag>}
                                    {status === 'PENDING' &&
                                        <bp4.Tag intent={bp4.Intent.WARNING}>in bearbeitung</bp4.Tag>}
                                    {status === 'CONFIRMED' && <bp4.Tag intent={bp4.Intent.SUCCESS}>bestätigt</bp4.Tag>}
                                </td>
                                <td>
                                    <bp4.Icon icon={IconNames.PERSON}/>
                                    {contactName} <br/>
                                    {(email !== null && email !== '') &&
                                        <>
                                            <bp4.Icon icon={IconNames.ENVELOPE}/>
                                            <a href={`mailto:${email}`}>{email}</a><br/></>}
                                    {(phone !== null && phone !== '') &&
                                        <>
                                            <bp4.Icon icon={IconNames.PHONE}/>
                                            <a href={`tel:${phone}`}>{phone}</a><br/></>}
                                </td>
                                <td>
                                    <bp4.Icon icon={IconNames.PEOPLE}/>
                                    {students.length}
                                </td>
                                <td>
                                    <bp4.ButtonGroup>
                                        {status === 'IN_CREATION' &&
                                            <bp4.AnchorButton
                                                intent={bp4.Intent.SUCCESS}
                                                icon={IconNames.CONFIRM}
                                                onClick={() => confirmBooking(id)}/>}
                                        <bp4.AnchorButton
                                            intent={bp4.Intent.PRIMARY}
                                            icon={IconNames.OPEN_APPLICATION}
                                            onClick={() => openSchoolView(id)}/>
                                        <bp4.AnchorButton
                                            intent={bp4.Intent.DANGER}
                                            icon={IconNames.DELETE}
                                            disabled={students.length > 0}
                                            onClick={() => deleteBooking(id, name)}/>
                                        {id !== showTable && <bp4.AnchorButton intent={bp4.Intent.PRIMARY} icon={IconNames.CHEVRON_DOWN}
                                                           onClick={() => setShowTable(id)}/>}
                                        {id === showTable && <bp4.AnchorButton intent={bp4.Intent.PRIMARY} icon={IconNames.CHEVRON_UP}
                                                                               onClick={() => setShowTable(null)}/>}
                                    </bp4.ButtonGroup>
                                </td>
                            </tr>
                            <tr key={`${id}-activities`}>
                                <td colSpan={5}>
                                    <bp4.HTMLTable style={{width: '100%'}} bordered striped hidden={id !== showTable}>
                                        <thead>
                                        <tr>
                                            <th>Anfänger</th>
                                            <th>#</th>
                                            <th>Fortgeschrittene</th>
                                            <th>#</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {Object.values(_.groupBy(activities, ({name}) => name)).map(activities => {
                                            if(activities.length === 0)
                                                return <></>;
                                            // @ts-ignore
                                            const {name} = activities[0];
                                            return <tr key={name}>
                                                {
                                                    // @ts-ignore
                                                    activities.map(({id, name, level}) => {
                                                        return <>
                                                            <td key={`${name}-${level}-name`}>
                                                                {name}
                                                            </td>
                                                            <td>
                                                                {
                                                                    // @ts-ignore
                                                                    students.map(({activities}) => activities).flat().filter(({id: iid}) => id === iid).length}
                                                            </td>
                                                        </>;
                                                    })}
                                                {activities.length === 1 && <><td></td><td></td></>}
                                            </tr>;
                                        })}
                                        </tbody>
                                    </bp4.HTMLTable>
                                </td>
                            </tr>
                        </>;
                    })}
                    </tbody>
                </bp4.HTMLTable>}
        </div>
        <div className={bp4.Classes.DIALOG_FOOTER}>
            <div className={bp4.Classes.DIALOG_FOOTER_ACTIONS}>
                <bp4.ButtonGroup>
                    <bp4.Button
                        icon={IconNames.NEW_LINK}
                        intent={bp4.Intent.SUCCESS}
                        text="Neuen Schullink erstellen"
                        onClick={() => showNewBookingDialog()}/>
                    <bp4.Button text="Abbrechen" onClick={() => close()}/>
                </bp4.ButtonGroup>
            </div>
        </div>
    </bp4.Dialog>;
}