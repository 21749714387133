import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';

import * as bp3 from '@blueprintjs/core';
import {IconNames} from '@blueprintjs/icons';
import * as rc from 'recoil';
import {useMatch, useNavigate} from 'react-router-dom';

import * as util from '../utils';
import * as state from '../state';

import {UploadDialog} from './UploadDialog';
import {formattedActivityNameWithTime} from './common';
import {api} from "../api";
import {SettingsDialog} from "./SettingsDialog";
import {BookingsDialog} from "./BookingsDialog";

const PARTICIPATION_LIMIT = 'ParticipationLimit';
const UNSCHEDULED_STUDENT = 'UnscheduledStudent';

function WarningPopover({warnings}: { warnings: any[] }) {
    const history = useNavigate();
    return (
        <div className="App-warnings" style={{padding: 25}}>
            {warnings.map((w, i, arr) => (
                <div key={w.id} className="space-between"
                     style={{alignItems: 'center', marginBottom: i + 1 === arr.length ? 0 : 20}}>
                    <div style={{width: 380}}>
                        <div style={{marginBottom: 3}}>{w.type === PARTICIPATION_LIMIT
                            ? formattedActivityNameWithTime(w.slot)
                            : w.type === UNSCHEDULED_STUDENT
                                ? w.student.school.name
                                : ''}</div>
                        <div style={{marginBottom: 3}} className={bp3.Classes.TEXT_MUTED}>{util.weeklyString({
                            from: util.startOfISOWeekFromYearWeek(w.yearWeek.year, w.yearWeek.weekNumber),
                            to: util.endOfISOWeekFromYearWeek(w.yearWeek.year, w.yearWeek.weekNumber),
                        })}</div>
                        <div className="danger-text">{w.message}</div>
                    </div>
                    <div style={{marginLeft: 20}}>
                        {[PARTICIPATION_LIMIT, UNSCHEDULED_STUDENT, 'StudentMissingActivity', 'SutdentHasTooManyActivities'].includes(w.type) &&
                            <bp3.Button
                                className={bp3.Classes.POPOVER_DISMISS}
                                text="Überprüfen"
                                large
                                onClick={() => {
                                    if (w.type === PARTICIPATION_LIMIT) {
                                        history(`/${w.yearWeek.year}/${w.yearWeek.weekNumber}/Sportart/${w.slot.activity.name}/${util.shortenId(w.slot.id)}`);
                                    } else {
                                        history(`/${w.yearWeek.year}/${w.yearWeek.weekNumber}/Schule/${util.shortenId(w.student.school.id)}`);
                                    }
                                }}
                            />}
                    </div>
                </div>
            ))}
        </div>
    );
}


export function TitleBar({
                             isOpen,
                             setIsOpen
                         }: { isOpen: boolean, setIsOpen: Dispatch<SetStateAction<boolean>>, isShareOpen: boolean, setIsShareOpen: Dispatch<SetStateAction<boolean>> }) {
    const [darkMode, setDarkMode] = rc.useRecoilState(util.darkModeState);

    const {state: warningsState, contents: warnings} = util.useRecoilValueLoadable(state.warnings);

    const showWarning = (warnings?.length || 0) > 0;

    const matchWithId = useMatch({path: '/:yyyy/:ww/:sort/:filter/:id'});
    const matchWithoutId = useMatch({path: '/:yyyy/:ww/:sort/:filter'});
    const {
        yyyy: year,
        ww: week
    } = (matchWithId || matchWithoutId)?.params as unknown as { yyyy: string, ww: string, sort: string, filter: string, id: string } ?? {};

    const [openBookings, setOpenBookings] = useState([]);
    useEffect(() => {
        (async () => {
            const response = await fetch(api(`/api/v1/bookings/${year}/${week}/open`));
            if (response.status === 200)
                setOpenBookings(await response.json());
        })();
    }, [setOpenBookings, year, week]);

    const [showBookings, setShowBookings] = useState<boolean>(false);
    const openBookingsDialog = () => setShowBookings(true);
    const closeBookingsDialog = () => setShowBookings(false);

    const [openSettings, setOpenSettings] = useState(false);

    return (
        <bp3.Navbar>
            <bp3.NavbarGroup>
                <bp3.NavbarHeading>
                    <strong>Hotel Royal X</strong>
                </bp3.NavbarHeading>
                {/* <bp3.NavbarHeading>© NAPPS GmbH (<a href="https://napps.solutions/">Support</a>)</bp3.NavbarHeading> */}
            </bp3.NavbarGroup>
            <bp3.NavbarGroup align={bp3.Alignment.RIGHT}>
                <bp3.Switch
                    checked={darkMode}
                    innerLabel={'🌞'}
                    innerLabelChecked={'🌜'}
                    onChange={e => setDarkMode(e.currentTarget.checked)}
                    style={{marginBottom: 0, marginRight: '1rem'}}
                    large
                />
                {warningsState === 'hasError' && <>
                    <div className="danger-text">Sportplanprobleme konnten nicht geladen werden!</div>
                    <bp3.Button icon={IconNames.REPEAT} intent={bp3.Intent.DANGER}
                                onClick={() => window.location.reload()} style={{marginLeft: 5}}>Neu laden
                    </bp3.Button>
                </>}
                {showWarning && (
                    <bp3.Popover boundary="viewport" content={<WarningPopover warnings={warnings ?? []}/>}
                                 canEscapeKeyClose={true}>
                        <bp3.Button icon={IconNames.WARNING_SIGN} intent={bp3.Intent.DANGER}>
                            {warnings?.length === 1
                                ? `1 Sportplan Problem`
                                : `${warnings?.length} Sportplan Probleme`}
                        </bp3.Button>
                    </bp3.Popover>
                )}
                <bp3.Divider/>
                <bp3.Button
                    icon={IconNames.CALENDAR}
                    intent={openBookings.length > 0 ? bp3.Intent.WARNING : bp3.Intent.PRIMARY}
                    text={`Buchungen${openBookings.length > 0 ? ` (${openBookings.length} offen)` : ''}`}
                    onClick={() => openBookingsDialog()}/>
                <bp3.Divider/>
                <bp3.Button icon={IconNames.UPLOAD} intent={bp3.Intent.PRIMARY}
                            onClick={React.useCallback(() => setIsOpen(true), [setIsOpen])}>Neue Buchung
                </bp3.Button>
                <bp3.Divider />
                <bp3.Button icon={IconNames.DOWNLOAD} intent={bp3.Intent.PRIMARY}
                            onClick={() => { window.open(`${process.env.REACT_APP_API || window.location.origin}/api/v1/plans/${year}/${week}/activities/Uerbsicht.xlsx`) }}>Aktivitätsübersicht herunterladen
                </bp3.Button>
                <bp3.Divider/>
                <bp3.Button icon={IconNames.PRINT} onClick={() => window.print()}>Drucken</bp3.Button>
                <bp3.Divider/>
                <bp3.Button icon={IconNames.SETTINGS} onClick={() => setOpenSettings(true)}/>
            </bp3.NavbarGroup>
            <UploadDialog isOpen={isOpen} setIsOpen={setIsOpen}/>
            <SettingsDialog isOpen={openSettings} close={() => setOpenSettings(false)}/>
            <BookingsDialog isOpen={showBookings} close={() => {
                closeBookingsDialog();
                window.location.reload();
            }} yearWeek={{year, week}}/>
        </bp3.Navbar>
    )
}
